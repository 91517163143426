import { useMemo, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAppThunkDispatch } from 'state/store';
import {
  setTaskIdToEdit,
  selectTaskIdToEdit,
  setTaskIdToDuplicate,
} from 'state/ProjectTasksList/actions/actionsSlice';
import {
  setSelectedRows,
  selectAllTasksSelected,
  selectExcludedTasks,
  selectSelectedTasks,
} from 'state/ProjectTasksList/actions/actionsSlice';
import { PATHS } from 'utils/constants';

export interface UseTaskRowActionsParams {
  isCentralizedTasks: boolean;
}

interface UseTaskRowActionsReturn {
  openTakInSidePanel: (taskId: string) => void;
  handleClickTaskRow: (taskId: string, projectId: string) => void;
  handleDuplicateTask: (taskId: string) => void;
  handleSelectRow: (taskId: string, selected: boolean) => void;
  isTaskRowSelected: (taskId: string) => boolean;
}

interface LocationState {
  from: string;
}

const useTaskRowActions: (
  params?: UseTaskRowActionsParams
) => UseTaskRowActionsReturn = (params) => {
  const dispatch = useAppThunkDispatch();
  const location = useLocation<LocationState>();
  const history = useHistory();
  const isCentralizedTasks = params?.isCentralizedTasks ?? false;

  const taskIdToEdit = useSelector(selectTaskIdToEdit);
  const allTasksSelected = useSelector(selectAllTasksSelected);
  const excludedTasks = useSelector(selectExcludedTasks);
  const selectedTasks = useSelector(selectSelectedTasks);

  const navigateToTaskDetailPage = useCallback(
    (taskId: string, projectId: string) => {
      const state = location.state;
      let fromPage = state?.from;
      let url = `${projectId}/tasks/${taskId}`;
      if (isCentralizedTasks) {
        url = `project/${url}`;
        fromPage = PATHS.TASKS_LIST_PAGE;
      }
      history.push(url, { from: fromPage });
    },
    [location, history, isCentralizedTasks]
  );

  const openTakInSidePanel = useCallback(
    (taskId: string) => {
      dispatch(setTaskIdToEdit(taskId));
    },
    [dispatch]
  );

  const handleClickTaskRow = useCallback(
    (taskId: string, projectId: string) => {
      const isSidePanelOpen = !!taskIdToEdit;
      if (isSidePanelOpen && taskIdToEdit !== taskId) {
        openTakInSidePanel(taskId);
      } else {
        navigateToTaskDetailPage(taskId, projectId);
      }
    },
    [navigateToTaskDetailPage, openTakInSidePanel, taskIdToEdit]
  );

  const handleDuplicateTask = useCallback(
    (taskId: string) => {
      dispatch(setTaskIdToDuplicate(taskId));
    },
    [dispatch]
  );

  const handleSelectRow = useCallback(
    (id: string, selected: boolean) => {
      dispatch(setSelectedRows({ id, selected }));
    },
    [dispatch]
  );

  const isTaskRowSelected = useCallback(
    (taskId: string) => {
      if (allTasksSelected) {
        return !excludedTasks.includes(taskId);
      }
      return selectedTasks.includes(taskId);
    },
    [allTasksSelected, excludedTasks, selectedTasks]
  );

  return useMemo(
    () => ({
      handleClickTaskRow,
      openTakInSidePanel,
      handleDuplicateTask,
      handleSelectRow,
      isTaskRowSelected,
    }),
    [
      handleClickTaskRow,
      openTakInSidePanel,
      handleDuplicateTask,
      handleSelectRow,
      isTaskRowSelected,
    ]
  );
};

export default useTaskRowActions;

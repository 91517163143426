import intl from 'react-intl-universal';
import classNames from 'classnames';
import { Typography } from '@getsynapse/design-system';
import loadingError from 'assets/images/loading-error.svg';
import notFoundError from 'assets/images/not-found-error.svg';
import { LEARNING_CENTRE_LINK } from 'utils/constants';
import { SERVER_ERROR_CODES } from 'utils/constants';

const loadErrorMessage: Record<number, string> = {
  [SERVER_ERROR_CODES.FORBIDDEN]: 'LOADING_ERROR.FORBIDDEN',
  [SERVER_ERROR_CODES.NOT_FOUND]: 'LOADING_ERROR.NOT_FOUND',
};

const LoadingError = ({
  headerHeight,
  className,
  errorCode = 403,
}: {
  headerHeight: string;
  className?: string;
  errorCode?: number;
}) => {
  const errorCodeMessage = intl.get(
    loadErrorMessage[errorCode] ?? 'LOADING_ERROR.GENERIC'
  );
  const errorImage =
    errorCode === SERVER_ERROR_CODES.NOT_FOUND ? notFoundError : loadingError;

  const renderErrorContent = () => {
    if (
      errorCode === SERVER_ERROR_CODES.FORBIDDEN ||
      errorCode === SERVER_ERROR_CODES.NOT_FOUND
    ) {
      return (
        <Typography variant='h4' className='mb-2 text-center w-80 leading-7'>
          {errorCodeMessage}
        </Typography>
      );
    }
    return (
      <>
        <Typography variant='h4' weight='medium' className='mb-2'>
          {intl.get('LOADING_ERROR.TITLE')}
        </Typography>
        <Typography variant='body' className='mb-4 text-center w-100 leading-7'>
          {errorCodeMessage}
        </Typography>
      </>
    );
  };

  return (
    <div
      className={classNames(
        'flex flex-col items-center justify-center w-full',
        className
      )}
      style={{ height: `calc(100vh - ${headerHeight})` }}
      data-testid='loading-error'
    >
      <img src={errorImage} alt='Error' className='mb-8' />
      {renderErrorContent()}
      <div className='flex gap-x-1'>
        <Typography className='text-primary'>
          {intl.get('LOADING_ERROR.ASSISTANCE')}
        </Typography>
        <a
          href={LEARNING_CENTRE_LINK}
          className='text-purple-darker'
          target='_blank'
          rel='noreferrer'
        >
          {intl.get('LOADING_ERROR.LINK')}
        </a>
      </div>
    </div>
  );
};

export default LoadingError;

import { useMemo, useState } from 'react';
import debounce from 'lodash/debounce';

import { TableOperationHeader } from '@getsynapse/design-system';

interface RequestsTableOperationHeaderProps {
  onSearchChange: (value: string) => void;
}

const RequestsTableOperationHeader = ({
  onSearchChange,
}: RequestsTableOperationHeaderProps) => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  const debouncedSearchChange = useMemo(() => {
    return debounce((value: string) => {
      onSearchChange(value);
    }, 500);
  }, [onSearchChange]);

  const handleSearchChange = (value: string) => {
    setSearchTerm(value);
    debouncedSearchChange(value);
  };

  return (
    <TableOperationHeader
      inputValue={searchTerm}
      setInputValue={handleSearchChange}
    />
  );
};

export default RequestsTableOperationHeader;

import { FC } from 'react';
import intl from 'react-intl-universal';
import { tailwindOverride, Typography } from '@getsynapse/design-system';
import type { UpdatedDependency } from 'types/store/taskDependencies';
import TruncatedDependencyTitle from '../TruncatedDependencyTitle/TruncatedDependencyTitle';

const dateFormater = new Intl.DateTimeFormat('en-US', {
  month: 'short',
  year: 'numeric',
  day: 'numeric',
});

const UpdatedDependencyItem: FC<UpdatedDependency> = ({
  id,
  displayId,
  name,
  disabled = false,
  originalStartDate,
  originalDueDate,
  newStartDate,
  newDueDate,
}) => {
  const formatDate = (date: string) => {
    if (!date) {
      return '';
    }
    return dateFormater.format(new Date(date));
  };

  return (
    <div
      className={tailwindOverride(
        'w-full min-h-12 px-4 py-3',
        'flex gap-x-2 items-center'
      )}
      aria-label={`updated-dependency-${id}`}
    >
      <div className='flex items-center gap-x-2' style={{ width: '45%' }}>
        <TruncatedDependencyTitle displayId={displayId} name={name} />
        {disabled && (
          <Typography variant='label' className='text-neutral'>
            {intl.get('DEACTIVATED')}
          </Typography>
        )}
      </div>
      <div
        className='flex items-center gap-x-2 justify-start'
        style={{ width: '55%' }}
      >
        <Typography variant='label' className='text-neutral'>
          {`${formatDate(originalStartDate)} - ${formatDate(originalDueDate)}`}
        </Typography>
        <Typography variant='label' weight='strong' className='text-primary'>
          →
        </Typography>
        <Typography variant='label' weight='medium'>
          {`${formatDate(newStartDate)} - ${formatDate(newDueDate)}`}
        </Typography>
      </div>
    </div>
  );
};

export default UpdatedDependencyItem;

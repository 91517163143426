import { Modal, Typography } from '@getsynapse/design-system';
import intl from 'react-intl-universal';

const UnsavedChangesModal = ({
  isOpen,
  setIsOpen,
  onConfirm,
  overlayClassName = 'z-10',
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: () => void;
  overlayClassName?: string;
}) => {
  const handleConfirm = () => {
    onConfirm();
    handleClose();
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      actionButtons={[
        {
          children: intl.get('UNSAVED_CHANGES.DISCARD_BUTTON'),
          onClick: handleConfirm,
          className:
            'bg-warning-light text-neutral-black hover:bg-warning-dark active:bg-warning-darker',
        },
        {
          children: intl.get('GO_BACK'),
          variant: 'secondary',
          onClick: handleClose,
        },
      ]}
      overlayClassName={overlayClassName}
      closeModal={handleClose}
      isOpen={isOpen}
      title={intl.get('UNSAVED_CHANGES.TITLE')}
      titleIcon={{
        name: 'alert-circle-sharp',
        className: 'text-warning-darker',
      }}
      childrenClassName='font-normal text-md line-height-6'
      size='medium'
      aria-label={intl.get('UNSAVED_CHANGES.TITLE')}
    >
      <Typography>{intl.get('UNSAVED_CHANGES.ARE_YOU_SURE')}</Typography>
    </Modal>
  );
};

export default UnsavedChangesModal;

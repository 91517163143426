import { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Button, Typography } from '@getsynapse/design-system';
import { isUserOrganizationAdmin, isLDTeamManager } from 'state/User/userSlice';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import goalsEmptyState from 'assets/images/goals-empty-state.svg';
import AddGoalModal from './components/AddGoalModal/AddGoalModal';
import LoadingError from 'Molecules/LoadingError/LoadingError';
import SkeletonLoader from './components/SkeletonLoader';
import {
  fetchStrategyGoals,
  selectStrategyGoals,
  selectStrategyGoalsStatus,
} from 'state/StrategyGoals/StrategyGoalsSlice';
import { SLICE_STATUS } from 'utils/constants';
import GoalCard from './components/GoalCard/GoalCard';

const StrategyGoalsPage = () => {
  const dispatch = useDispatch();

  const strategyGoalsStatus = useSelector(selectStrategyGoalsStatus);
  const strategyGoals = useSelector(selectStrategyGoals);
  const isUserAdmin = useSelector(isUserOrganizationAdmin);
  const isUserTeamManager = useSelector(isLDTeamManager);

  const [isAddGoalModalOpen, setIsAddGoalModalOpen] = useState(false);

  const hasGoals = strategyGoals.length > 0;

  useEffect(() => {
    dispatch(fetchStrategyGoals());
  }, [dispatch]);

  const openAddGoalModal = () => setIsAddGoalModalOpen(true);
  const closeAddGoalModal = () => setIsAddGoalModalOpen(false);

  return (
    <>
      {(isUserAdmin || isUserTeamManager) && (
        <AddGoalModal
          isOpen={isAddGoalModalOpen}
          closeModal={closeAddGoalModal}
        />
      )}
      <div className='flex items-center justify-between px-6'>
        <PageTitle
          titleComponent={
            <div className='flex items-center'>
              <span>{intl.get('STRATEGY_GOALS.HEADER_TITLE')}</span>
              <span className='ml-4 font-normal	text-base'>
                {intl.get('STRATEGY_GOALS.FISCAL_YEAR')}
              </span>
            </div>
          }
          className='p-0'
        />
        {(isUserAdmin || isUserTeamManager) && (
          <Button data-testid='add-goal_button' onClick={openAddGoalModal}>
            {intl.get('STRATEGY_GOALS.ADD_GOAL')}
          </Button>
        )}
      </div>
      {strategyGoalsStatus === SLICE_STATUS.LOADING && <SkeletonLoader />}
      {strategyGoalsStatus === SLICE_STATUS.IDLE && (
        <div
          className={classNames(
            'h-goals overflow-y-auto flex flex-col w-full items-center px-6 pb-6',
            {
              'justify-center ': !hasGoals,
            }
          )}
        >
          {!hasGoals && (
            <>
              <img src={goalsEmptyState} alt='' className='mb-6' />
              <Typography>{intl.get('STRATEGY_GOALS.EMPTY_STATE')}</Typography>
            </>
          )}
          {hasGoals && (
            <div className='space-y-4 w-full'>
              {strategyGoals.map((goal) => (
                <GoalCard key={goal.id} goal={goal} />
              ))}
            </div>
          )}
        </div>
      )}
      {strategyGoalsStatus === SLICE_STATUS.FAILED && (
        <LoadingError headerHeight='6rem' className='px-6' />
      )}
    </>
  );
};

export default StrategyGoalsPage;

import { useCallback, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { useAppThunkDispatch } from 'state/store';
import { Checkbox } from '@getsynapse/design-system';
import {
  setAllTasksSelected,
  selectAllTasksSelected,
  selectExcludedTasks,
} from 'state/ProjectTasksList/actions/actionsSlice';

const SelectAllRowsCheckbox = () => {
  const dispatch = useAppThunkDispatch();
  const allTasksSelected = useSelector(selectAllTasksSelected);
  const excludedTasks = useSelector(selectExcludedTasks);
  const checked = allTasksSelected
    ? excludedTasks.length === 0
      ? true
      : 'mixed'
    : false;

  const toggleAllRowsSelected = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(setAllTasksSelected(event.target.checked));
    },
    [dispatch]
  );

  return (
    <Checkbox
      onChange={toggleAllRowsSelected}
      label=''
      inputProps={{
        className: 'mr-0 mt-0',
      }}
      checked={checked}
    />
  );
};

export default SelectAllRowsCheckbox;

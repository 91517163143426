export enum UpdateHoursStatus {
  Succes = 'success',
  Failed = 'failed',
  Partial = 'partial',
}

export enum FailedOperation {
  Add = 'add',
  Delete = 'delete',
}

export interface Hours {
  id: string;
  hours: number;
  date: string;
  submitterId: string;
}

export interface Submitter {
  id: string;
  name: string;
  avatarUrl: string | null;
  email: string;
  status: string;
}

export interface ActualHoursState {
  loading: boolean;
  actualHours: {
    [submitterId: string]: Hours[];
  };
  submitters: { [id: string]: Submitter };
  updateHoursStatus?: UpdateHoursStatus;
  failedOperation?: FailedOperation;
}

export interface NewActualHoursEntry {
  userId: string;
  hours: number;
  date: string;
}

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import intl from 'react-intl-universal';
import truncate from 'lodash/truncate';
import {
  Typography,
  tailwindOverride,
  Tag,
  Avatar,
} from '@getsynapse/design-system';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import userAvatar from 'assets/icons/user-avatar.svg';
import {
  fetchStrategyGoalDetail,
  resetGoal,
  selectStrategyGoalDetail,
  selectStrategyGoalDetailOwner,
  selectStrategyGoalDetailTeam,
} from 'state/StrategyGoalDetail/StrategyGoalDetailSlice';
import LoadingError from 'Molecules/LoadingError/LoadingError';
import { SLICE_STATUS } from 'utils/constants';
import classNames from 'classnames';

const GoalDetailsPage = () => {
  const dispatch = useDispatch();

  const { goalId } = useParams<{
    goalId: string;
  }>();

  useEffect(() => {
    if (goalId) {
      dispatch(fetchStrategyGoalDetail(goalId));
    }
    return () => {
      dispatch(resetGoal());
    };
  }, [dispatch, goalId]);

  const strategyGoalDetailOwner = useSelector(selectStrategyGoalDetailOwner);
  const strategyGoalDetail = useSelector(selectStrategyGoalDetail);
  const isGoalDetailEmpty = Object.keys(strategyGoalDetail).length === 0;
  const strategyGoalDetailTeam = useSelector(selectStrategyGoalDetailTeam);
  return (
    <>
      {strategyGoalDetail.status === SLICE_STATUS.FAILED && (
        <LoadingError
          headerHeight='6rem'
          errorCode={strategyGoalDetail.errorCode}
        />
      )}
      {strategyGoalDetail.status === SLICE_STATUS.IDLE && !isGoalDetailEmpty && (
        <div className='h-full flex flex-col'>
          <PageTitle
            titleComponent={truncate(strategyGoalDetail.value.title, {
              length: 100,
            })}
            dataCy='goal-title'
            className='leading-6'
          />
          <div className='h-full px-8 pt-2.5 pb-8 w-full flex flex-row'>
            <div className='w-3/4' />
            <div className='h-full pb-8 right-0 p-4 w-90 2xl:w-3/12 border border-neutral-lighter-two rounded'>
              <Typography
                variant='h5'
                className='pb-4 font-semibold'
                data-cy='goal-details'
              >
                {intl.get('STRATEGY_GOALS.ADD_GOAL_MODAL.GOAL_DETAILS')}
              </Typography>
              <Typography
                variant='body2'
                className='font-semibold'
                data-cy='goal-title_label'
              >
                {intl.get('STRATEGY_GOALS.ADD_GOAL_MODAL.GOAL_TITLE')}
              </Typography>
              <Typography
                variant='body'
                className='pb-4'
                data-cy='goal-title_value'
              >
                {strategyGoalDetail.value.title}
              </Typography>
              <Typography
                variant='body2'
                className='font-semibold'
                data-cy='accountable-team_label'
              >
                {intl.get('STRATEGY_GOALS.ADD_GOAL_MODAL.ACCOUNTABLE_TEAM')}
              </Typography>
              <Typography
                variant='body'
                className={classNames('pb-4', {
                  'text-neutral-dark': strategyGoalDetailTeam?.deleted,
                })}
                data-cy='accountable-team_value'
              >
                {strategyGoalDetailTeam && strategyGoalDetailTeam.name}
              </Typography>
              <Typography
                variant='body2'
                className='font-semibold'
                data-cy='owner_label'
              >
                {intl.get('STRATEGY_GOALS.ADD_GOAL_MODAL.OWNER')}
              </Typography>
              <div data-cy='owner_value' className='flex flex-row pt-1'>
                <Avatar
                  initial={
                    strategyGoalDetailOwner
                      ? strategyGoalDetailOwner.initials
                      : undefined
                  }
                  size='small'
                  imageSrc={
                    strategyGoalDetailOwner
                      ? strategyGoalDetailOwner.avatar!
                      : userAvatar
                  }
                  name={
                    strategyGoalDetailOwner
                      ? strategyGoalDetailOwner.label
                      : intl.get('STRATEGY_GOALS.FORMER_USER')
                  }
                  disabled={strategyGoalDetailOwner?.disabled}
                  showTooltip
                  className={
                    !strategyGoalDetailOwner
                      ? 'border-neutral bg-neutral'
                      : undefined
                  }
                  iconProps={
                    !strategyGoalDetailOwner
                      ? { className: 'w-6 h-6' }
                      : undefined
                  }
                />
                {!strategyGoalDetailOwner && (
                  <div className='flex ml-2 min-w-0 gap-x-2.5 pb-4'>
                    <Typography variant='body2' className='text-neutral-dark'>
                      {intl.get('STRATEGY_GOALS.FORMER_USER')}
                    </Typography>
                  </div>
                )}
                {strategyGoalDetailOwner && (
                  <div className='flex ml-2 min-w-0 gap-x-2.5 pb-4'>
                    <Typography
                      variant='body2'
                      className={tailwindOverride(
                        'whitespace-nowrap overflow-hidden overflow-ellipsis flex-1',
                        {
                          'text-neutral-dark': strategyGoalDetailOwner.disabled,
                        }
                      )}
                    >
                      {strategyGoalDetailOwner.label}
                    </Typography>
                    {strategyGoalDetailOwner.disabled && (
                      <Tag
                        label={intl.get('DEACTIVATED')}
                        className='right-0 bg-neutral-lighter-two'
                        textClassName='text-neutral-dark'
                      />
                    )}
                  </div>
                )}
              </div>
              <Typography
                variant='body2'
                className='font-semibold'
                data-cy='time-period_label'
              >
                {intl.get('STRATEGY_GOALS.ADD_GOAL_MODAL.TIME_PERIOD')}
              </Typography>
              <Typography
                variant='body'
                className='pb-4'
                data-cy='time-period_value'
              >
                {`${strategyGoalDetail.value.timePeriod.type} ${strategyGoalDetail.value.timePeriod.year}`}
              </Typography>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GoalDetailsPage;

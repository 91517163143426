import intl from 'react-intl-universal';
import { useMemo, useState, ChangeEvent, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { Modal, FormItem, TextArea } from '@getsynapse/design-system';
import addGoalIcon from 'assets/icons/add-goal.svg';
import { NewGoalData } from 'utils/types/strategyGoals';
import { GOAL_TYPES } from 'utils/constants/strategyGoals';
import { createGoal } from 'state/StrategyGoals/StrategyGoalsSlice';
import useSnackbarNotification from 'Hooks/useSnackbarNotification';
import TimePeriod from './components/TimePeriod';
import GoalOwner from './components/GoalOwner';
import GoalTeam from './components/GoalTeam';
import { PATHS } from 'utils/constants';

type AddGoalModalProps = {
  isOpen: boolean;
  closeModal: () => void;
};

const initialGoalData: NewGoalData = {
  goal: {
    title: '',
    type: '',
    timePeriod: '',
    year: 0,
  },
  goalOwners: [],
};

const AddGoalModal = ({ isOpen, closeModal }: AddGoalModalProps) => {
  const dispatch = useDispatch();

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [newGoalData, setNewGoalData] = useState<NewGoalData>(initialGoalData);
  const [isSavingInProgress, setIsSavingInProgress] = useState(false);
  const [goalLink, setGoalLink] = useState('');

  const {
    isNotificationVisible,
    snackbarProps,
    showNotification,
    SnackbarNotification,
  } = useSnackbarNotification();

  const isCreateButtonDisabled = useMemo(() => {
    const { title, type, timePeriod, year } = newGoalData.goal;
    const { goalOwners, goalTeams } = newGoalData;

    return (
      !title ||
      !type ||
      !timePeriod ||
      !year ||
      goalOwners?.length === 0 ||
      (type === 'team' && goalTeams?.length === 0)
    );
  }, [newGoalData]);

  const onFieldChange = useCallback(
    (value: string | string[] | number, prop: string) => {
      setNewGoalData((prevData: NewGoalData) => {
        if (prop in prevData?.goal) {
          return {
            ...prevData,
            goal: {
              ...prevData.goal,
              [prop]: value,
            },
          };
        } else {
          return {
            ...prevData,
            [prop]: value,
          };
        }
      });
    },
    []
  );

  const debouncedOnFieldChange = debounce((value: string, prop: string) => {
    onFieldChange(value, prop);
  }, 500);

  const handleAutoResize = useCallback(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      const remHeight =
        textarea.scrollHeight /
        parseFloat(getComputedStyle(document.documentElement).fontSize);
      const newHeight = remHeight > 2.625 ? `${remHeight}rem` : '2.625rem';
      textarea.style.height = newHeight;
    }
  }, []);

  const createNewGoal = async () => {
    if (isCreateButtonDisabled) return;
    setIsSavingInProgress(true);
    if (newGoalData.goal.type === GOAL_TYPES.COMPANY && newGoalData.goalTeams) {
      delete newGoalData.goalTeams;
    }
    try {
      const createdGoal = await dispatch(createGoal(newGoalData));
      const createdGoalId = get(createdGoal, 'payload.id');
      setGoalLink(`${PATHS.STRATEGY_GOAL_DETAILS}/${createdGoalId}`);
      showNotification({
        notificationTitle: intl.get(
          'STRATEGY_GOALS.ADD_GOAL_MODAL.SUCCESS_TITLE'
        ),
        hasActionButton: true,
      });
    } catch {
      showNotification({
        notificationTitle: intl.get(
          'STRATEGY_GOALS.ADD_GOAL_MODAL.ERROR_TITLE'
        ),
        notificationMessage: intl.get(
          'STRATEGY_GOALS.ADD_GOAL_MODAL.ERROR_MESSAGE'
        ),
        notificationVariant: 'error',
      });
    } finally {
      setIsSavingInProgress(false);
      closeModalAnResetData();
    }
  };

  const closeModalAnResetData = () => {
    if (newGoalData !== initialGoalData) {
      setNewGoalData(initialGoalData);
    }
    closeModal();
  };

  return (
    <>
      {isNotificationVisible && snackbarProps && (
        <SnackbarNotification
          {...snackbarProps}
          cta={
            <a href={goalLink} className='ml-9 underline text-primary-dark'>
              {intl.get('STRATEGY_GOALS.ADD_GOAL_MODAL.SUCCESS_MESSAGE')}
            </a>
          }
        />
      )}
      <Modal
        title={intl.get('STRATEGY_GOALS.ADD_GOAL_MODAL.TITLE')}
        titleIcon={{ src: addGoalIcon }}
        aria-label={intl.get('STRATEGY_GOALS.ADD_GOAL_MODAL.TITLE')}
        isOpen={isOpen}
        size='medium'
        childrenClassName='max-h-90'
        actionButtons={[
          {
            children: intl.get('STRATEGY_GOALS.ADD_GOAL_MODAL.ADD_BUTTON'),
            hasDisabledStyle: isCreateButtonDisabled,
            buttonTooltip: isCreateButtonDisabled
              ? {
                  children: intl.get(
                    'STRATEGY_GOALS.ADD_GOAL_MODAL.ADD_BUTTON_TOOLTIP'
                  ),
                  openMode: 'hover1',
                  timeout: 0,
                }
              : undefined,
            'data-testid': 'add_goal_button',
            onClick: createNewGoal,
            loading: isSavingInProgress,
          },
          {
            children: intl.get('CANCEL'),
            variant: 'tertiary',
            onClick: closeModalAnResetData,
            'data-testid': 'cancel_button',
          },
        ]}
        data-testid='add-goal_modal'
        closeModal={closeModalAnResetData}
      >
        <FormItem
          label={intl.get('STRATEGY_GOALS.ADD_GOAL_MODAL.GOAL_TITLE')}
          labelProps={{ required: true }}
          className='mb-4'
        >
          <TextArea
            ref={textareaRef}
            textAreaProps={{
              placeholder: intl.get(
                'STRATEGY_GOALS.ADD_GOAL_MODAL.GOAL_TITLE_PLACEHOLDER'
              ),
              rows: 1,
              className: 'overflow-hidden placeholder-neutral',
              'data-testid': 'add_goal_title_input',
              style: { height: '2.625rem' },
            }}
            name='add_goal_title_input'
            onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
              handleAutoResize();
              debouncedOnFieldChange(event.target.value, 'title');
            }}
          />
        </FormItem>
        <GoalTeam onChange={onFieldChange} />
        <GoalOwner onChange={onFieldChange} />
        <TimePeriod onChange={onFieldChange} />
      </Modal>
    </>
  );
};

export default AddGoalModal;

import { useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
  addActualHours,
  deleteActualHours,
  setFailedOperation,
  setUpdateHoursStatus,
} from 'state/ActualHours/actualHoursSlice';
import {
  Hours,
  NewActualHoursEntry,
  UpdateHoursStatus,
  FailedOperation,
} from 'types/store/actualHours';

export interface UpdateActualHoursParams {
  taskId: string;
  onSuccessCallback?: () => void;
}

interface UpdateActualHoursReturn {
  canSave: boolean;
  updateNewActualHoursEntries: (
    newActualHoursEntries: NewActualHoursEntry[]
  ) => void;
  handleDeletedHours: (hours: Hours) => void;
  handleSaveActualHours: () => void;
  reset: () => void;
}

const useUpdateActualHours: (
  params: UpdateActualHoursParams
) => UpdateActualHoursReturn = ({ taskId, onSuccessCallback = () => {} }) => {
  const dispatch = useDispatch();
  const [newActualHoursEntries, setNewActualHoursEntries] = useState<
    NewActualHoursEntry[]
  >([]);
  const [hoursEntriesToRemove, setHoursEntriesToRemove] = useState<Hours[]>([]);
  const canSave = useMemo(
    () => newActualHoursEntries.length > 0 || hoursEntriesToRemove.length > 0,
    [newActualHoursEntries, hoursEntriesToRemove]
  );

  const updateNewActualHoursEntries = useCallback(
    (newActualHoursEntries: NewActualHoursEntry[]) => {
      setNewActualHoursEntries(newActualHoursEntries);
    },
    []
  );

  const handleDeletedHours = useCallback((hours: Hours) => {
    setHoursEntriesToRemove((prev) => [...prev, hours]);
  }, []);

  const reset = useCallback(() => {
    setNewActualHoursEntries([]);
    setHoursEntriesToRemove([]);
  }, []);

  const handleAddActualHours = useCallback(
    async (taskId: string, actualHours: NewActualHoursEntry[]) => {
      let success = false;
      try {
        await dispatch(
          addActualHours({
            taskId,
            actualHours,
          })
        );
        success = true;
      } catch (error) {
        dispatch(setFailedOperation(FailedOperation.Add));
      }
      return success;
    },
    [dispatch]
  );

  const handleDeleteActualHours = useCallback(
    async (taskId: string, hours: Hours[]) => {
      let success = false;
      try {
        await dispatch(
          deleteActualHours({
            taskId,
            hours,
          })
        );
        success = true;
      } catch (error) {
        dispatch(setFailedOperation(FailedOperation.Delete));
      }
      return success;
    },
    [dispatch]
  );

  const handleSaveActualHours = useCallback(async () => {
    let hoursAdded = true;
    let hoursDeleted = true;
    if (newActualHoursEntries.length > 0) {
      hoursAdded = await handleAddActualHours(taskId, newActualHoursEntries);
    }
    if (hoursEntriesToRemove.length > 0) {
      hoursDeleted = await handleDeleteActualHours(
        taskId,
        hoursEntriesToRemove
      );
    }
    let result = UpdateHoursStatus.Succes;
    if (!hoursAdded && !hoursDeleted) {
      result = UpdateHoursStatus.Failed;
    } else if (!hoursAdded || !hoursDeleted) {
      result = UpdateHoursStatus.Partial;
    }

    dispatch(setUpdateHoursStatus(result));
    reset();
    onSuccessCallback();
  }, [
    dispatch,
    taskId,
    newActualHoursEntries,
    hoursEntriesToRemove,
    onSuccessCallback,
    reset,
    handleAddActualHours,
    handleDeleteActualHours,
  ]);

  return useMemo(
    () => ({
      canSave,
      updateNewActualHoursEntries,
      handleDeletedHours,
      handleSaveActualHours,
      reset,
    }),
    [
      updateNewActualHoursEntries,
      canSave,
      handleSaveActualHours,
      handleDeletedHours,
      reset,
    ]
  );
};

export default useUpdateActualHours;

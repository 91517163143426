import { useState, useCallback, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { NewActualHoursEntry } from 'types/store/actualHours';

interface AddActualHoursEntriesHookParams {
  userId: string;
  updateNewActualHoursEntries: (
    newActualHoursEntries: NewActualHoursEntry[]
  ) => void;
}

interface AddActualHoursEntriesHookReturn {
  addEntry: () => void;
  removeEntry: (entryId: string) => void;
  updateEntry: (
    entryId: string,
    key: keyof Omit<NewActualHoursEntry, 'userId'>,
    value: number | string
  ) => void;
  actualHoursEntries: Record<string, NewActualHoursEntry>;
}

const useAddActualHoursEntries: (
  params: AddActualHoursEntriesHookParams
) => AddActualHoursEntriesHookReturn = ({
  userId,
  updateNewActualHoursEntries,
}) => {
  const [actualHoursEntries, setActualHoursEntries] = useState<
    Record<string, NewActualHoursEntry>
  >(() => ({
    [uuid()]: {
      userId,
      hours: 0,
      date: new Date().toLocaleDateString('en-US'),
    },
  }));

  useEffect(() => {
    const notEmptyEntries = Object.values(actualHoursEntries).filter(
      (entry) => entry.hours > 0
    );
    updateNewActualHoursEntries(notEmptyEntries);
  }, [actualHoursEntries, updateNewActualHoursEntries]);

  const addEntry = useCallback(() => {
    setActualHoursEntries((prevState) => ({
      ...prevState,
      [uuid()]: {
        userId,
        hours: 0,
        date: new Date().toLocaleDateString('en-US'),
      },
    }));
  }, [userId]);

  const updateEntry = useCallback(
    (
      entryId: string,
      key: keyof Omit<NewActualHoursEntry, 'userId'>,
      value: number | string
    ) => {
      setActualHoursEntries((prevState) => ({
        ...prevState,
        [entryId]: {
          ...prevState[entryId],
          [key]: value,
        },
      }));
    },
    []
  );

  const removeEntry = useCallback(
    (entryId: string) => {
      const { [entryId]: _, ...rest } = actualHoursEntries;
      setActualHoursEntries(rest);
    },
    [actualHoursEntries]
  );

  return {
    addEntry,
    updateEntry,
    removeEntry,
    actualHoursEntries,
  };
};

export default useAddActualHoursEntries;

import { FC, useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { debounce } from 'lodash';
import { UsersPicker } from '@getsynapse/design-system';
import { UserOption } from '@getsynapse/design-system/dist/Molecules/UsersPicker/UsersPicker';
import {
  searchUsers,
  selectSearchAssignees,
  selectSearchAssigneesResult,
} from 'state/TasksList/Search/searchSlice';
import { RootState } from 'state/store';
import { TaskAssignee } from 'types/store/tasks';

interface AssigneeFilterFieldProps {
  user: string[];
  onChange: (user: string[]) => void;
}

function getInitial(user: TaskAssignee) {
  return (
    user.name
      ?.split(' ')
      .map((name) => name[0])
      .join('') || 'NA'
  );
}

const AssigneeFilterField: FC<AssigneeFilterFieldProps> = ({
  user,
  onChange,
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const users = useSelector((state: RootState) =>
    selectSearchAssignees(state, searchValue.length > 0)
  );
  const userMap = useSelector(selectSearchAssigneesResult);

  const usersList = useMemo(
    () =>
      users?.map((u) => {
        const initial = getInitial(u);

        return {
          value: u.id,
          label: u.name,
          avatar: {
            imageSrc: u.avatarUrl,
            initial,
          },
          disabled: u.disabled ?? false,
        };
      }),
    [users]
  );

  const onFilterChange = useRef(
    debounce(
      (assigneeName: string) =>
        dispatch(searchUsers({ assigneeName, includeDisabled: true })),
      500
    )
  );

  const handleFilterChange = (assigneeName: string) => {
    setSearchValue(assigneeName);
    onFilterChange.current(assigneeName);
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(searchUsers({ includeDisabled: true }));
  }, [dispatch]);

  let selectedUsersList = useMemo(
    () =>
      user.map((u) => {
        const mappedUser = userMap[u] || {};

        const selectedOption: UserOption = {
          value: u,
          label: mappedUser.name || 'N/A',
          avatar: {
            imageSrc: mappedUser.avatarUrl,
            initial: getInitial(mappedUser),
          },
          disabled: mappedUser.disabled ?? false,
        };

        return selectedOption;
      }),
    [userMap, user]
  );

  return (
    <UsersPicker
      triggerText={intl.get('FILTER_GENERAL.PLACEHOLDER')}
      usersList={usersList}
      selectedUsersList={selectedUsersList}
      onFilterChange={handleFilterChange}
      onChange={(selectedUsers: UserOption[]) => {
        const user = selectedUsers.map((user) => user.value);
        onChange(user);
      }}
      triggerProps={{
        'aria-label': intl.get('TASK.ASSIGNEE'),
        'data-testid': 'assignee-filter',
      }}
    />
  );
};

export default AssigneeFilterField;

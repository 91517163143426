import { useState } from 'react';
import intl from 'react-intl-universal';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { TabPanels, TabPanel, Tabs } from '@reach/tabs';
import { Tab, TabList } from '@getsynapse/design-system';
import { PATHS, SETTINGS_TABS } from 'utils/constants';
import {
  selectProjectProcessIdToUpdate,
  setProcessIdToUpdate,
} from 'state/Processes/processesSlice';
import PageTitle from 'Molecules/PageTitle/PageTitle';
import AccountSettings from './AccountSettings/AccountSettings';
import PlatformSettings from './Configurations/Configurations';
import UsersListPage from './UsersListPage/UsersListPage';
import Teams from './TeamsSettings/TeamsSettings';

const SettingsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { activeTab } = useParams<{ activeTab: string }>();
  const tabMapping = {
    [SETTINGS_TABS.ACCOUNT]: 0,
    [SETTINGS_TABS.USERS]: 1,
    [SETTINGS_TABS.TEAMS]: 2,
    [SETTINGS_TABS.CONFIGURATIONS]: 3,
  };
  const [activeIndex, setActiveIndex] = useState(
    activeTab ? tabMapping[activeTab] : 0
  );
  const isUpdatingProjectProcess =
    useSelector(selectProjectProcessIdToUpdate) !== null;

  const handleChangeTab = (tab: number) => {
    setActiveIndex(tab);
    history.push(`${PATHS.SETTINGS}/${Object.keys(tabMapping)[tab]}`);
    if (isUpdatingProjectProcess) {
      dispatch(setProcessIdToUpdate(null));
    }
  };

  return (
    <Tabs
      className='flex flex-col min-h-full max-w-full'
      index={activeIndex || 0}
      onChange={handleChangeTab}
    >
      <PageTitle
        titleComponent={intl.get('SIDEBAR.SETTINGS')}
        headerChildren={
          <TabList type='subHeader'>
            <Tab index={0} type='subHeader' data-cy='account'>
              {intl.get('ENTITIES.ACCOUNT', { num: 1 })}
            </Tab>
            <Tab index={1} type='subHeader' data-cy='users'>
              {intl.get('ENTITIES.USER', { num: 2 })}
            </Tab>
            <Tab index={2} type='subHeader' data-cy='teams'>
              {intl.get('ENTITIES.TEAM', { num: 2 })}
            </Tab>
            <Tab index={3} type='subHeader' data-cy='platform-tab'>
              {intl.get('ENTITIES.CONFIGURATION', { num: 2 })}
            </Tab>
          </TabList>
        }
      />

      <TabPanels className='mx-6 flex flex-grow'>
        <TabPanel key={0} className='flex-grow'>
          <AccountSettings />
        </TabPanel>
        <TabPanel key={1} className='flex-grow max-w-full'>
          <UsersListPage />
        </TabPanel>
        <TabPanel key={2} className='flex-grow'>
          <Teams />
        </TabPanel>
        <TabPanel key={3} className='flex-grow max-w-full'>
          <PlatformSettings />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default SettingsPage;

import { useEffect, useCallback, useMemo, useState, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { SidePanel } from '@getsynapse/design-system';
import { SLICE_STATUS, TASK_STATUS } from 'utils/constants';
import {
  selectTaskIdToEdit,
  setTaskIdToEdit,
  setShouldRefreshTasks as setShouldRefreshProjectTasks,
} from 'state/ProjectTasksList/actions/actionsSlice';
import { isUserOrganizationAdmin as selectIfUserIsOrganizationAdmin } from 'state/User/userSlice';
import { setShouldRefreshTasks as setShouldRefreshAllTasks } from 'state/TasksList/allTasks/allTasksSlice';
import {
  fetchTaskDetail,
  selectCanEdit,
  selectTaskSliceStatus,
  selectIsTaskDisabled,
  selectTaskDueDate,
  selectTaskProject,
  selectTaskStatus,
  resetState as resetTaskDetailState,
  selectUpdateTaskStatus,
  setUpdateTaskStatus,
  setTaskDetailToUpdate,
} from 'state/TaskDetail/taskSlice';
import { selectFetchUpdatedDependenciesPreviewStatus } from 'state/TaskDependencies/DependenciesUpdateSlice';
import useSidePanel from 'Hooks/useSidePanel';
import useUpdateTask from 'Pages/TaskDetailPage/hooks/useUpdateTask';
import useSnackbarNotification from 'Hooks/useSnackbarNotification';
import { validateIfTaskDueDateIsPast } from 'Pages/TaskDetailPage/TaskDetailForm/helpers/helpers';
import HeaderElement from './HeaderElement/HeaderElement';
import SidePanelContent from './SidePanelContent/SidePanelContent';
import DependenciesUpdatePreview from 'Pages/TaskDetailPage/Dependencies/DependenciesUpdatePreview/DependenciesUpdatePreview';

const TaskDetailSidePanel: FC<{
  isCentralizedTask?: boolean;
}> = ({ isCentralizedTask = false }) => {
  const dispatch = useDispatch();
  const { taskDependenciesR2 = false } = useFlags();
  const [showUnsavedChangesBanner, setShowUnsavedChangesBanner] =
    useState<boolean>(false);
  const taskProject = useSelector(selectTaskProject);
  const taskDueDate = useSelector(selectTaskDueDate);
  const canUpdateTask = useSelector(selectCanEdit);
  const isTaskDisabled = useSelector(selectIsTaskDisabled);
  const fetchTaskDetailStatus = useSelector(selectTaskSliceStatus);
  const taskIdToEdit = useSelector(selectTaskIdToEdit);
  const taskStatus = useSelector(selectTaskStatus);
  const updateTaskRequestStatus = useSelector(selectUpdateTaskStatus);
  const fetchDependenciesUpdatePreview = useSelector(
    selectFetchUpdatedDependenciesPreviewStatus
  );
  const isUserOrganizationAdmin = useSelector(selectIfUserIsOrganizationAdmin);
  const isTaskOnHold = taskStatus === TASK_STATUS.ON_HOLD;
  const isTaskCompleted = taskStatus === TASK_STATUS.COMPLETED;
  const isTaskDueDatePast = useMemo(
    () => validateIfTaskDueDateIsPast(taskDueDate),
    [taskDueDate]
  );

  const canShiftDependenciesDates =
    taskDependenciesR2 && isUserOrganizationAdmin;

  const closePanelCallback = useCallback(() => {
    dispatch(setTaskIdToEdit(null));
    dispatch(resetTaskDetailState());
  }, [dispatch]);

  const { openPanel, isPanelOpen, onClose, closePanel } =
    useSidePanel(closePanelCallback);

  useEffect(() => {
    if (taskIdToEdit) {
      dispatch(fetchTaskDetail({ taskId: taskIdToEdit }));
      openPanel();
    }
  }, [taskIdToEdit, dispatch, openPanel]);

  const {
    SnackbarNotification,
    snackbarProps,
    isNotificationVisible,
    showNotification,
  } = useSnackbarNotification();

  const {
    task,
    requiredFieldsErrors,
    canSubmitChanges,
    hasAssignedUsers,
    updateTaskDetail,
    updateTaskAssignees,
    updateTaskDateRange,
    updateTaskStatus,
    handleUpdateTask,
  } = useUpdateTask({
    showNotification,
    canShiftDependenciesDates,
  });

  const onUpdateTaskSuccessCallback = useCallback(async () => {
    const taskName = task?.name!;
    showNotification({
      notificationTitle: '',
      notificationMessage: intl.get(
        'TASKS.TASK_DETAIL_PAGE.UPDATE_SUCCESSFUL',
        { taskName }
      ),
    });
    if (isCentralizedTask) {
      dispatch(setShouldRefreshAllTasks(true));
    } else {
      dispatch(setShouldRefreshProjectTasks(true));
    }
    dispatch(setTaskDetailToUpdate(undefined));
    dispatch(setUpdateTaskStatus(undefined));
    setShowUnsavedChangesBanner(false);
    closePanel();
  }, [dispatch, closePanel, isCentralizedTask, showNotification, task]);

  const onUpdateTaskFailedCallback = useCallback(() => {
    showNotification({
      notificationVariant: 'error',
      notificationTitle: intl.get('TASKS.TASK_DETAIL_PAGE.UPDATE_ERROR.TITLE'),
      notificationMessage: intl.get(
        'TASKS.TASK_DETAIL_PAGE.UPDATE_ERROR.MESSAGE'
      ),
    });
    dispatch(setUpdateTaskStatus(undefined));
  }, [showNotification, dispatch]);

  useEffect(() => {
    if (updateTaskRequestStatus === SLICE_STATUS.SUCCESS) {
      onUpdateTaskSuccessCallback();
    }

    if (updateTaskRequestStatus === SLICE_STATUS.FAILED) {
      onUpdateTaskFailedCallback();
    }
  }, [
    updateTaskRequestStatus,
    onUpdateTaskSuccessCallback,
    onUpdateTaskFailedCallback,
  ]);

  const confirmIfCanClosePanel = useCallback(() => {
    if (canSubmitChanges) {
      setShowUnsavedChangesBanner(true);
    } else {
      closePanel();
    }
  }, [canSubmitChanges, closePanel]);

  const footerButtons = useMemo(
    () => [
      {
        children: intl.get('UPDATE'),
        disabled: !canUpdateTask || !canSubmitChanges,
        onClick: handleUpdateTask,
        loading:
          updateTaskRequestStatus === SLICE_STATUS.UPDATING ||
          fetchDependenciesUpdatePreview === SLICE_STATUS.LOADING,
      },
      {
        children: intl.get('CANCEL'),
        variant: 'tertiary',
        onClick: closePanel,
      },
    ],
    [
      closePanel,
      canUpdateTask,
      handleUpdateTask,
      canSubmitChanges,
      updateTaskRequestStatus,
      fetchDependenciesUpdatePreview,
    ]
  );

  return (
    <>
      {taskDependenciesR2 && <DependenciesUpdatePreview />}
      <SidePanel
        isOpen={isPanelOpen}
        onClose={confirmIfCanClosePanel}
        footerButtons={footerButtons}
        isLoading={fetchTaskDetailStatus === SLICE_STATUS.LOADING}
        headerElement={
          <HeaderElement
            taskId={taskIdToEdit}
            projectId={taskProject?.id ?? ''}
            status={taskStatus}
            onClose={onClose}
          />
        }
      >
        {taskIdToEdit && (
          <SidePanelContent
            task={task}
            project={taskProject}
            requiredFieldsErrors={requiredFieldsErrors}
            hasAssignedUsers={hasAssignedUsers}
            canUpdateTask={canUpdateTask}
            isTaskDisabled={isTaskDisabled}
            isTaskOnHold={isTaskOnHold}
            isTaskDueDatePast={!isTaskCompleted && isTaskDueDatePast}
            updateTaskDetail={updateTaskDetail}
            updateTaskAssignees={updateTaskAssignees}
            updateTaskDateRange={updateTaskDateRange}
            updateTaskStatus={updateTaskStatus}
            showUnsavedChangesBanner={showUnsavedChangesBanner}
          />
        )}
      </SidePanel>
      {isNotificationVisible && snackbarProps && (
        <SnackbarNotification {...snackbarProps} />
      )}
    </>
  );
};

export default TaskDetailSidePanel;

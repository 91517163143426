import { useEffect } from 'react';
import intl from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Typography } from '@getsynapse/design-system';
import {
  fetchProjectGoals,
  selectProjectGoals,
  selectProjectGoalsStatus,
  selectTotalProjectGoals,
} from 'state/ProjectGoals/ProjectGoalsSlice';
import GoalCard from './GoalCard/GoalCard';
import ProjectGoalsSkeletonLoader from './ProjectGoalsSkeletonLoader';
import { SLICE_STATUS } from 'utils/constants';

type ProjectGoalsProps = {
  projectId: string;
  isReadOnly: boolean;
};

const ProjectGoals = ({ projectId, isReadOnly }: ProjectGoalsProps) => {
  const dispatch = useDispatch();
  const goals = useSelector(selectProjectGoals);
  const totalGoals = useSelector(selectTotalProjectGoals);
  const status = useSelector(selectProjectGoalsStatus);

  useEffect(() => {
    if (projectId) {
      dispatch(fetchProjectGoals(projectId));
    }
  }, [dispatch, projectId]);

  return (
    <>
      <Typography variant='h5' className='mb-0.5 mt-8'>
        {intl.get('PROJECT_DETAIL.PROJECT_GOALS.TITLE')}
      </Typography>
      <Typography variant='body2' className='text-primary-dark mb-2'>
        {intl.get('PROJECT_DETAIL.PROJECT_GOALS.SUBTITLE')}
      </Typography>
      {!isReadOnly && (
        <Button
          variant='tertiary'
          iconName='add-circle'
          data-testid='goal-link_button'
        >
          {intl.get('PROJECT_DETAIL.PROJECT_GOALS.LINK_BUTTON')}
        </Button>
      )}
      {status === SLICE_STATUS.LOADING && <ProjectGoalsSkeletonLoader />}
      {status === SLICE_STATUS.IDLE && goals.length > 0 && (
        <div className='space-y-2 mt-2'>
          {goals.map((goal) => (
            <GoalCard goal={goal} key={goal.id} isReadOnly={isReadOnly} />
          ))}
        </div>
      )}
      {status === SLICE_STATUS.IDLE && goals.length === 0 && totalGoals === 0 && (
        <Typography variant='body2' className='mt-4'>
          {intl.get('PROJECT_DETAIL.PROJECT_GOALS.NO_GOALS_LINKED')}
        </Typography>
      )}
      {(status === SLICE_STATUS.IDLE && goals.length === 0 && totalGoals > 0) ||
        (status === SLICE_STATUS.FAILED && (
          <Typography variant='body2' className='mt-4'>
            {intl.get('PROJECT_DETAIL.PROJECT_GOALS.NO_GOALS_AVAILABLE')}
          </Typography>
        ))}
    </>
  );
};

export default ProjectGoals;

import { FC, ChangeEvent, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import {
  updateTaskDetail,
  setShouldRedirectAfterUpdate,
} from 'state/TaskDetail/taskSlice';
import { Toggle } from '@getsynapse/design-system';

interface DisableTaskToogleProps {
  taskId: string;
  isTaskDisabled?: boolean;
  disabled?: boolean;
}

const DisableTaskToogle: FC<DisableTaskToogleProps> = ({
  taskId,
  isTaskDisabled = false,
  disabled = false,
}) => {
  const dispatch = useDispatch();

  const handleToggleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(setShouldRedirectAfterUpdate(false));
      dispatch(
        updateTaskDetail({
          task: {
            id: taskId,
            disabled: !event.target.checked,
          },
        })
      );
    },
    [taskId, dispatch]
  );

  return (
    <Toggle
      offText={intl.get('TASKS.TASK_DETAIL_PAGE.ENABLE_TASK')}
      onChange={handleToggleChange}
      onText={intl.get('TASKS.TASK_DETAIL_PAGE.ENABLE_TASK')}
      className='mr-1 my-auto'
      checked={!isTaskDisabled}
      toggleTextPosition='left'
      inputProps={{
        'aria-label': 'task-disable-toggle',
      }}
      disabled={disabled}
    />
  );
};

export default DisableTaskToogle;

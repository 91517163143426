import { FC } from 'react';
import {
  tailwindOverride,
  Typography,
  IconButton,
} from '@getsynapse/design-system';
import { Hours } from 'types/store/actualHours';

interface TimeEntryProps {
  hours: Hours;
  onDeleteHours: (hour: Hours) => void;
  canDelete?: boolean;
}

const dateFormatter = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
});

const TimeEntry: FC<TimeEntryProps> = ({
  hours,
  onDeleteHours,
  canDelete = true,
}) => {
  return (
    <div
      className={tailwindOverride('flex justify-between', 'py-1 px-2 group')}
      aria-label={`time-entry-${hours.id}`}
    >
      <Typography variant='body'>
        {dateFormatter.format(new Date(hours.date))}
      </Typography>
      <div className='flex gap-x-4 items-center'>
        <Typography variant='body'>{`${hours.hours}h`}</Typography>
        {canDelete && (
          <div className='w-8 h-full flex items-center'>
            <IconButton
              name='trash'
              onClick={() => onDeleteHours(hours)}
              className={tailwindOverride(
                'hidden text-xl group-hover:flex text-neutral-dark'
              )}
              aria-label={`delete-time-entry`}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TimeEntry;

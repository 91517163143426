import { FormItem, Dropdown } from '@getsynapse/design-system';
import intl from 'react-intl-universal';
import { timePeriodOptions } from 'utils/constants/strategyGoals';
import { TimePeriodOption } from 'utils/types/strategyGoals';

const TimePeriod = ({
  onChange,
}: {
  onChange: (value: string | number, prop: string) => void;
}) => {
  const changeHandler = (option: TimePeriodOption) => {
    onChange(option.value.timePeriod, 'timePeriod');
    onChange(Number(option.value.year), 'year');
  };

  return (
    <FormItem
      label={intl.get('STRATEGY_GOALS.ADD_GOAL_MODAL.TIME_PERIOD')}
      labelProps={{ required: true }}
    >
      <Dropdown
        options={timePeriodOptions}
        onChange={changeHandler}
        triggerProps={{
          placeholder: intl.get(
            'STRATEGY_GOALS.ADD_GOAL_MODAL.TIME_PERIOD_PLACEHOLDER'
          ),
          'data-testid': 'time_period_dropdown',
        }}
        listProps={{
          'data-testid': 'time_period_list',
        }}
      />
    </FormItem>
  );
};

export default TimePeriod;

import {
  DailyTeamCapacity,
  DailyCapacityPerWeek,
  WeeksAndDaysArray,
  DailyCapacityPropsMap,
} from 'utils/types/dailyTeamsCapacity';
import {
  getNextWeekDay,
  getPrevWeekDay,
  getMondayOfTheWeek,
} from 'Pages/TeamsPage/views/TeamsCapacity/components/DailyCapacity/helpers';

export const generateFetchDailyCapacityURLs: (
  startDate: string | null,
  endDate: string | null,
  baseUrl: string
) => string[] = (startDate, endDate, baseUrl) => {
  const urlsArray: string[] = [];
  if (!startDate || !endDate) {
    return urlsArray;
  }
  const pivot = new Date(startDate.replace(/-/g, '/'));
  const end = new Date(endDate.replace(/-/g, '/'));
  const url = `${baseUrl}?startDate=:startDate&endDate=:endDate`;
  while (pivot.getTime() <= end.getTime()) {
    let dayOfTheWeek = pivot.getDay();
    if (dayOfTheWeek > 0) {
      let rangeStart = new Date(pivot.getTime());
      let rangeEnd = rangeStart;
      while (dayOfTheWeek < 6) {
        if (pivot.getTime() > end.getTime()) {
          break;
        }
        rangeEnd = new Date(pivot.getTime());
        pivot.setDate(pivot.getDate() + 1);
        dayOfTheWeek = pivot.getDay();
      }
      urlsArray.push(
        url
          .replace(':startDate', rangeStart.toLocaleDateString('en-US'))
          .replace(':endDate', rangeEnd.toLocaleDateString('en-US'))
      );
    }
    pivot.setDate(pivot.getDate() + 1);
  }
  return urlsArray;
};

export const removeTeamsFromDailyCapacityState = (
  dailyCapacity: DailyTeamCapacity,
  teamIds: string[]
) => {
  const newDailyCapacity = { ...dailyCapacity };
  for (const teamId of teamIds) {
    if (teamId in newDailyCapacity) {
      delete newDailyCapacity[teamId];
    }
  }
  return newDailyCapacity;
};

export const updateDailyCapacityPerWeek: (
  currentCapacity: DailyCapacityPerWeek,
  newCapacity: DailyCapacityPerWeek
) => DailyCapacityPerWeek = (currentCapacity, newCapacity) => {
  const updatedDailyTeamCapacity = { ...currentCapacity };
  const weeks = Object.keys(newCapacity);
  for (const week of weeks) {
    if (week in currentCapacity) {
      updatedDailyTeamCapacity[week] = {
        ...updatedDailyTeamCapacity[week],
        dailyCapacity: newCapacity[week].dailyCapacity,
        days: {
          ...updatedDailyTeamCapacity[week].days,
          ...newCapacity[week].days,
        },
      };
    } else {
      updatedDailyTeamCapacity[week] = newCapacity[week];
    }
  }
  return updatedDailyTeamCapacity;
};

export const generateFetchDailyCapacityURLFromDateRange: (
  rangeStart: string,
  rangeEnd: string,
  baseUrl: string
) => string = (rangeStart, rangeEnd, baseUrl) => {
  const url = `${baseUrl}?startDate=:startDate&endDate=:endDate`;
  const startDate = new Date(rangeStart.replace(/-/g, '/'));
  const endDate = new Date(rangeEnd.replace(/-/g, '/'));
  return url
    .replace(':startDate', startDate.toLocaleDateString('en-US'))
    .replace(':endDate', endDate.toLocaleDateString('en-US'));
};

export const generateFetchPrevOrNextDayCapacityURL = (
  url: string,
  day: string,
  type: 'prevDay' | 'nextDay'
) => {
  const dayDate = new Date(day.replace(/-/g, '/'));
  let newWeekDay = dayDate;
  if (type === 'prevDay') {
    newWeekDay = getPrevWeekDay(dayDate);
  } else {
    newWeekDay = getNextWeekDay(dayDate);
  }
  return url.replace(':startDate', newWeekDay.toLocaleDateString('en-US'));
};

export const removeDayFromCapacityPerWeek: (
  currentDailyTeamCapacity: DailyCapacityPerWeek,
  day: string
) => DailyCapacityPerWeek = (
  currentDailyTeamCapacity: DailyCapacityPerWeek,
  day: string
) => {
  const dateFormatter = new Intl.DateTimeFormat('fr-CA');
  const updatedDailyTeamCapacity = { ...currentDailyTeamCapacity };
  const dayDate = new Date(day.replace(/-/g, '/'));
  const startOfWeek = getMondayOfTheWeek(dayDate);
  const weekKey = dateFormatter.format(startOfWeek);
  const dayKey = dateFormatter.format(dayDate);
  if (weekKey in updatedDailyTeamCapacity) {
    const dailyCapacities = updatedDailyTeamCapacity[weekKey];
    if (dayKey in dailyCapacities.days) {
      if (Object.keys(dailyCapacities.days).length === 1) {
        delete updatedDailyTeamCapacity[weekKey];
      } else {
        delete updatedDailyTeamCapacity[weekKey].days[dayKey];
      }
    }
  }
  return updatedDailyTeamCapacity;
};

export const generateDailyCapacityPropsMap: (
  dailyCapacityPerWeek: DailyCapacityPerWeek,
  weeksAndDaysArray: WeeksAndDaysArray
) => DailyCapacityPropsMap = (dailyCapacityPerWeek, weeksAndDaysArray) => {
  return Object.keys(weeksAndDaysArray).reduce<DailyCapacityPropsMap>(
    (acc: DailyCapacityPropsMap, week: string) => {
      const days = weeksAndDaysArray[week];
      if (week in dailyCapacityPerWeek) {
        const capacitiesPerWeek = dailyCapacityPerWeek[week];
        for (const day of days) {
          if (day in capacitiesPerWeek.days) {
            acc[day] = {
              assignedHours: parseFloat(
                capacitiesPerWeek.days[day].assigned as string
              ),
              dailyCapacity: parseFloat(
                capacitiesPerWeek.dailyCapacity as string
              ),
              timeOff: parseFloat(
                capacitiesPerWeek.days[day].timeOff as string
              ),
            };
          } else {
            acc[day] = null;
          }
        }
      } else {
        for (const day of days) {
          acc[day] = null;
        }
      }
      return acc;
    },
    {}
  );
};

export const generateTeamMemberId: (
  teamId: string,
  memberId: string
) => string = (teamId, memberId) => `team-${teamId}__member-${memberId}`;

export const addIdsToArrayIfNotExists: (
  idsToAdd: string[],
  idsArray: string[]
) => string[] = (idsToAdd, idsArray) => {
  if (idsArray.length === 0) {
    return idsToAdd;
  }
  const idsSet = new Set(idsArray);
  idsToAdd.forEach((id) => idsSet.add(id));
  return Array.from(idsSet);
};

export const addIdToArrayIfNotExists: (
  idsArray: string[],
  newId: string
) => string[] = (idsArray, newId) => {
  if (idsArray.includes(newId)) {
    return idsArray;
  }
  return idsArray.concat(newId);
};

import { FC } from 'react';
import intl from 'react-intl-universal';
import { Checkbox, FormItem } from '@getsynapse/design-system';
import { TaskListFilters } from 'types/store/tasksList';
import useTaskFilters from '../hooks/useTaskFilters';
import StatusFilterField from './StatusFilterField';
import TypeFilterField from './TypeFilterField';
import ProgramFilterField from './ProgramFilterField';
import ProjectFilterField from './ProjectFilterField';
import AssigneeFilterField from './AssigneeFilterField';
import DateRangeFilterField from './DateRangeFilterField';
import HoursRangeFilterField from './HoursRangeFilterField';

interface FiltersFormProps {
  filters: TaskListFilters;
  onUpdateFilters: (filters: TaskListFilters) => void;
  hideAssigneeFilter?: boolean;
  isCentralizedTasksPage?: boolean;
}

const FiltersForm: FC<FiltersFormProps> = ({
  filters,
  onUpdateFilters,
  hideAssigneeFilter = false,
  isCentralizedTasksPage = true,
}) => {
  const { updateBooleanFilters, updateArrayFilters, updateStringFilters } =
    useTaskFilters(onUpdateFilters);

  return (
    <form
      className='mt-8 flex flex-col space-y-5'
      aria-label='Filters form'
      onSubmit={(e) => e.preventDefault()}
    >
      {isCentralizedTasksPage && (
        <FormItem>
          <Checkbox
            defaultChecked={filters.disabled || false}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              updateBooleanFilters(filters, 'disabled', e.target.checked);
            }}
            label={intl.get('TASK.INCLUDE_DISABLED')}
          />
        </FormItem>
      )}
      <FormItem label={intl.get('TASK.STATUS')}>
        <StatusFilterField
          status={filters.status || []}
          onChange={(status: string[]) => {
            updateArrayFilters(filters, 'status', status);
          }}
        />
      </FormItem>
      {isCentralizedTasksPage && (
        <FormItem label={intl.get('ENTITIES.PROGRAM', { num: 1 })}>
          <ProgramFilterField
            program={filters.program || []}
            onChange={(program: string[]) => {
              updateArrayFilters(filters, 'program', program);
            }}
          />
        </FormItem>
      )}
      {isCentralizedTasksPage && (
        <FormItem label={intl.get('ENTITIES.PROJECT', { num: 1 })}>
          <ProjectFilterField
            selectedPrograms={filters.program || []}
            project={filters.project || []}
            onChange={(project: string[]) => {
              updateArrayFilters(filters, 'project', project);
            }}
          />
        </FormItem>
      )}
      <FormItem label={intl.get('TASK.TYPE')}>
        <TypeFilterField
          type={filters.type || []}
          onChange={(type: string[]) => {
            updateArrayFilters(filters, 'type', type);
          }}
        />
      </FormItem>
      {!hideAssigneeFilter && (
        <FormItem label={intl.get('TASK.ASSIGNEE')}>
          <AssigneeFilterField
            user={filters.user || []}
            onChange={(user: string[]) => {
              updateArrayFilters(filters, 'user', user);
            }}
          />
        </FormItem>
      )}
      <FormItem label={intl.get('TASK.START_DATE')}>
        <DateRangeFilterField
          fromDateValue={
            filters.startDateFrom ? new Date(filters.startDateFrom) : null
          }
          toDateValue={
            filters.startDateTo ? new Date(filters.startDateTo) : null
          }
          onFromDateChange={(date: string) => {
            updateStringFilters(filters, 'startDateFrom', date);
          }}
          onToDateChange={(date: string) => {
            updateStringFilters(filters, 'startDateTo', date);
          }}
          label={intl.get('TASK.START_DATE')}
        />
      </FormItem>
      <FormItem label={intl.get('TASK.DUE_DATE')}>
        <DateRangeFilterField
          fromDateValue={
            filters.dueDateFrom ? new Date(filters.dueDateFrom) : null
          }
          toDateValue={filters.dueDateTo ? new Date(filters.dueDateTo) : null}
          onFromDateChange={(date: string) => {
            updateStringFilters(filters, 'dueDateFrom', date);
          }}
          onToDateChange={(date: string) => {
            updateStringFilters(filters, 'dueDateTo', date);
          }}
          label={intl.get('TASK.DUE_DATE')}
        />
      </FormItem>
      <FormItem label={intl.get('TASK.ACTUAL_COMPLETION_DATE')}>
        <DateRangeFilterField
          fromDateValue={
            filters.completedDateFrom
              ? new Date(filters.completedDateFrom)
              : null
          }
          toDateValue={
            filters.completedDateTo ? new Date(filters.completedDateTo) : null
          }
          onFromDateChange={(date: string) => {
            updateStringFilters(filters, 'completedDateFrom', date);
          }}
          onToDateChange={(date: string) => {
            updateStringFilters(filters, 'completedDateTo', date);
          }}
          label={intl.get('TASK.ACTUAL_COMPLETION_DATE')}
        />
      </FormItem>
      <FormItem label={intl.get('TASK.ESTIMATED_HOURS')}>
        <HoursRangeFilterField
          fromHoursValue={filters.estimateHoursFrom || ''}
          toHoursValue={filters.estimateHoursTo || ''}
          onFromHoursChange={(hours: string) => {
            updateStringFilters(filters, 'estimateHoursFrom', hours);
          }}
          onToHoursChange={(hours: string) => {
            updateStringFilters(filters, 'estimateHoursTo', hours);
          }}
          label={intl.get('TASK.ESTIMATED_HOURS')}
        />
      </FormItem>
      <FormItem label={intl.get('TASK.ACTUAL_HOURS')}>
        <HoursRangeFilterField
          fromHoursValue={filters.actualHoursFrom || ''}
          toHoursValue={filters.actualHoursTo || ''}
          onFromHoursChange={(hours: string) => {
            updateStringFilters(filters, 'actualHoursFrom', hours);
          }}
          onToHoursChange={(hours: string) => {
            updateStringFilters(filters, 'actualHoursTo', hours);
          }}
          label={intl.get('TASK.ACTUAL_HOURS')}
        />
      </FormItem>
    </form>
  );
};

export default FiltersForm;

import { FC } from 'react';
import intl from 'react-intl-universal';
import {
  tailwindOverride,
  Typography,
  useElevation,
} from '@getsynapse/design-system';

interface RequestTableFooterProps {
  loaded?: number;
  total?: number;
}

const RequestsTableFooter: FC<RequestTableFooterProps> = ({
  loaded = 0,
  total = 0,
}) => {
  const skim = useElevation(1);
  return (
    <div
      className={tailwindOverride(
        'w-full px-6 py-2',
        'flex flex-1 items-center',
        'absolute bottom-0 left-0 z-10',
        'bg-neutral-white',
        skim
      )}
    >
      <Typography variant='body'>
        {intl.get('REQUESTS_LIST_PAGE.ACTIVE_REQUESTS.SHOWING_OF_TOTAL', {
          loaded,
          total,
        })}
      </Typography>
    </div>
  );
};

export default RequestsTableFooter;

import { useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { selectUserType } from 'state/User/userSlice';
import {
  getCurrentProjectId,
  getIsProjectArchived,
  getIsProjectCanceled,
  getIsProjectClosed,
} from 'state/Project/projectSlice';
import { PROJECT_USER_ACTIONS, USER_TYPES } from 'utils/constants';
import { useUserPermissionsContext } from 'Pages/ProjectPage/context/UserPermissionsContext';
import RenderNoRecords from 'Atoms/NoRecords/NoRecords';
import CreateTaskModal from 'Pages/TasksListPage/components/v2/CreateTaskModal/CreateTaskModal';
import EmptyTasks from 'assets/images/empty-tasks.svg';

const EmptyTableBody = () => {
  const { canUser } = useUserPermissionsContext();
  const userType = useSelector(selectUserType);
  const isProjectArchived = useSelector(getIsProjectArchived);
  const isProjectClosed = useSelector(getIsProjectClosed);
  const isProjectCanceled = useSelector(getIsProjectCanceled);
  const projectId = useSelector(getCurrentProjectId);
  const isUserFacilitator = userType === USER_TYPES.FACILITATOR;

  const shouldDisplayAddTaskButton =
    !isProjectCanceled &&
    !isProjectClosed &&
    !isProjectArchived &&
    (canUser(PROJECT_USER_ACTIONS.ADD_TASK) || isUserFacilitator);

  return (
    <RenderNoRecords
      caption={intl.get('TASKS.TABLE.EMPTY')}
      imageSrc={EmptyTasks}
      className='h-empty-table-body w-full'
      labelClassName='mt-0'
      imageClassName='-ml-4'
    >
      {shouldDisplayAddTaskButton && (
        <div className='flex-initial self-center mt-2'>
          <CreateTaskModal
            isCentralizedPage={false}
            projectId={projectId}
            isUserFacilitator={isUserFacilitator}
            canUserAddTask={canUser(PROJECT_USER_ACTIONS.ADD_TASK)}
          />
        </div>
      )}
    </RenderNoRecords>
  );
};

export default EmptyTableBody;

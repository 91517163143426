export interface TaskProject {
  id: string;
  title: string;
  status: string;
}

export interface TaskAssignee {
  id: string;
  name: string;
  email: string;
  status: string;
  avatarUrl: string;
  disabled?: boolean;
}

export enum TaskStatus {
  New = 'new',
  OnHold = 'on_hold',
  InProgress = 'in_progress',
  Completed = 'completed',
}

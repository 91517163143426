import { api } from './api';
import axios from 'axios';
import { Hours, Submitter, NewActualHoursEntry } from 'types/store/actualHours';

export interface FetchTaskActualHoursParams {
  taskId: string;
  userId?: string;
}

export interface FetchTaskActualHoursResponse {
  hours: Hours[];
  submitters: Record<string, Submitter>;
}

export interface CreateTaskActualHoursParams {
  taskId: string;
  actualHours: NewActualHoursEntry[];
}

export interface CreateTaskActualHoursResponse {
  successes: Hours[];
  failures: {
    submittedHour: NewActualHoursEntry;
    error: string;
  }[];
}

interface DeleteTaskActualHoursParams {
  taskId: string;
  ids: string[];
}

export const fetchTaskActualHours = async ({
  taskId,
}: FetchTaskActualHoursParams) => {
  const url = `/v2/tasks/${taskId}/submitted-hours`;
  const response = await api.get<FetchTaskActualHoursResponse>(url);
  if (axios.isAxiosError(response)) {
    throw response;
  }
  return response.data;
};

export const createTaskActualHours = async ({
  taskId,
  actualHours,
}: CreateTaskActualHoursParams) => {
  const url = `/v2/tasks/${taskId}/submitted-hours`;
  const response = await api.post<CreateTaskActualHoursResponse>(url, {
    submittedHours: actualHours,
  });
  if (axios.isAxiosError(response)) {
    throw response;
  }
  return response.data;
};

export const deleteTaskActualHours = async ({
  taskId,
  ids,
}: DeleteTaskActualHoursParams) => {
  const urlSearchParams = new URLSearchParams();
  ids.forEach((id) => urlSearchParams.append('ids[]', id));
  const url = `/v2/tasks/${taskId}/submitted-hours?${urlSearchParams.toString()}`;
  const response = await api.delete(url);
  if (axios.isAxiosError(response)) {
    throw response;
  }
  return response.data;
};

import {
  Button,
  tailwindOverride,
  useElevation,
} from '@getsynapse/design-system';
import intl from 'react-intl-universal';

interface TaskDetailFooterProps {
  onCancel: () => void;
  onSave: () => void;
  isUpdating?: boolean;
  taskDisabled: boolean;
  positionedAtPageBottom?: boolean;
}

const TaskDetailFooter = ({
  onCancel,
  onSave,
  isUpdating = false,
  taskDisabled,
  positionedAtPageBottom = false,
}: TaskDetailFooterProps) => {
  const footerElevation = useElevation(1);
  return (
    <div
      className={
        positionedAtPageBottom
          ? `w-full bg-neutral-white py-2 flex justify-end z-5 ${footerElevation}`
          : ''
      }
    >
      <div
        className={tailwindOverride('flex', {
          'space-x-2': !positionedAtPageBottom,
          'space-x-4 mr-12': positionedAtPageBottom,
        })}
      >
        {!positionedAtPageBottom && (
          <Button
            disabled={taskDisabled}
            onClick={onSave}
            data-cy='task-save-button'
            className={tailwindOverride({ 'cursor-default': taskDisabled })}
            loading={isUpdating}
          >
            {intl.get('TASKS.TASK_DETAIL_PAGE.SAVE_UPDATES_BUTTON')}
          </Button>
        )}
        <Button
          variant='secondary'
          onClick={onCancel}
          data-cy='task-cancel-button'
          className={tailwindOverride({ 'cursor-default': taskDisabled })}
        >
          {intl.get('TASKS.TASK_DETAIL_PAGE.CANCEL_UPDATES_BUTTON')}
        </Button>
        {positionedAtPageBottom && (
          <Button
            disabled={taskDisabled}
            onClick={onSave}
            data-cy='task-save-button'
            className={tailwindOverride({ 'cursor-default': taskDisabled })}
          >
            {intl.get('TASKS.TASK_DETAIL_PAGE.UPDATE_TASK_BUTTON')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default TaskDetailFooter;

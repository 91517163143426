import { useCallback, useEffect } from 'react';
import TaskBundleTableFilters from './components/TaskBundleTableFilters';
import TaskBundlesTable from './TaskBundleTable';
import Pagination from 'Organisms/Pagination/ClientPagination';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchTaskBundles,
  taskBundlesForTable,
  updateTaskBundlePagination,
} from 'state/TasksBundle/tasksBundleSlice';

const TaskBundlesList = () => {
  const dispatch = useDispatch();
  const taskBundles = useSelector(taskBundlesForTable);

  useEffect(() => {
    dispatch(fetchTaskBundles());
  }, [dispatch]);

  const fetchTaskBundlePagination = useCallback(
    (params) => {
      dispatch(updateTaskBundlePagination(params));
    },
    [dispatch]
  );

  return (
    <div className='mt-4 overflow-y-auto overflow-x-hidden'>
      <TaskBundleTableFilters />
      <TaskBundlesTable taskBundles={taskBundles.data} />
      <Pagination
        total={taskBundles.total}
        onChange={fetchTaskBundlePagination}
      />
    </div>
  );
};

export default TaskBundlesList;

import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState, ThunkApi } from 'state/store';
import { Request } from 'types/store/normalized';
import { Status } from 'utils/customTypes';
import { RequestStatus } from 'utils/types/request';
import { SLICE_STATUS } from 'utils/constants';
import { REQUEST_STATUSES } from 'utils/constants/request';
import { DEFAULT_PAGE_SIZE } from 'utils/constants/ui';

/* ============================== STATE SETUP =============================== */
interface RequestsState {
  status: Status;
  limit: number;
  links: { next?: string; last?: string };
  total: number;
  cursor?: string;
  searchParameter: string;
}

const initialState: RequestsState = {
  status: SLICE_STATUS.IDLE,
  limit: DEFAULT_PAGE_SIZE,
  links: { next: undefined, last: undefined },
  total: 0,
  searchParameter: '',
};

const requestsAdapter = createEntityAdapter<Request>();

/* ============================== REDUX THUNK =============================== */
function concurrencyCondition(
  thunkParameters: unknown,
  { getState }: { getState: () => RootState }
): boolean {
  const { status } = getState().normalized.requests;
  return status !== SLICE_STATUS.LOADING;
}

interface GetListParams {
  pageSize?: number;
  status?: readonly RequestStatus[];
  fetchNext?: boolean;
}
export const getRequests = createAsyncThunk<
  {
    data: Request[];
    totalCount: number;
    links: { next: string };
    cursor: string;
  },
  GetListParams,
  ThunkApi
>(
  'normalized/requests/getRequests',
  async (
    {
      pageSize = DEFAULT_PAGE_SIZE,
      status = Object.values(REQUEST_STATUSES),
      fetchNext = false,
    },
    { extra: { getRequestsApi }, getState }
  ) => {
    const state = getState();
    const { data, meta, links } = await getRequestsApi().getList({
      params: {
        pageSize,
        'filter[status]':
          status && status.length > 0 ? status.join(',') : undefined,
        'filter[search]': state.normalized.requests.searchParameter,
        after: fetchNext ? state.normalized.requests.cursor : undefined,
      },
    });
    return { data, totalCount: meta.totalCount, cursor: meta.cursor, links };
  },
  { condition: concurrencyCondition }
);

/* ================================= REDUCER ================================ */
const requestsSlice = createSlice({
  name: 'normalized/requests',
  initialState: requestsAdapter.getInitialState(initialState),
  reducers: {
    setSearchParameter: (state, action: PayloadAction<string>) => {
      state.ids = [];
      state.searchParameter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRequests.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(getRequests.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(getRequests.fulfilled, (state, action) => {
        requestsAdapter.upsertMany(state, action.payload.data);
        state.ids = Array.from(
          new Set([
            ...state.ids,
            ...action.payload.data.map((request) => request.id),
          ])
        );

        if (action.meta.arg.pageSize) {
          state.limit = action.meta.arg.pageSize;
        }
        state.links.last = state.links.next;
        state.links.next = action.payload.links.next;

        state.total = action.payload.totalCount;
        state.cursor = action.payload.cursor;

        state.status = SLICE_STATUS.SUCCESS;
      });
  },
});

/* ================================ ACTIONS ================================= */
export const { setSearchParameter } = requestsSlice.actions;

/* =============================== SELECTORS ================================ */
const defaultSelectors = requestsAdapter.getSelectors<RootState>(
  (state) => state.normalized.requests
);

const selectCanFetchMore = (state: RootState) =>
  !!state.normalized.requests.links.next;

const selectTotalRequests = (state: RootState) =>
  state.normalized.requests.total;

const selectLoadedRequests = (state: RootState) =>
  state.normalized.requests.ids.length;

const selectStatus = (state: RootState) => state.normalized.requests.status;

const selectSearchParameter = (state: RootState) =>
  state.normalized.requests.searchParameter;

export const requestsSelectors = {
  ...defaultSelectors,
  selectTotalRequests,
  selectLoadedRequests,
  selectCanFetchMore,
  selectStatus,
  selectSearchParameter,
};

export default requestsSlice.reducer;

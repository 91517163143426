import { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { FormItem, Dropdown } from '@getsynapse/design-system';
import {
  fetchStrategyTeams,
  selectStrategyGoalsTeams,
} from 'state/StrategyGoals/StrategyGoalsSlice';
import { isLDTeamManager, selectUserRole } from 'state/User/userSlice';
import { USER_ROLES } from 'utils/constants';
import { Option } from 'utils/customTypes';
import { GOAL_TYPES } from 'utils/constants/strategyGoals';

const GoalTeam = ({
  onChange,
}: {
  onChange: (value: string[] | string, prop: string) => void;
}) => {
  const dispatch = useDispatch();

  const teams = useSelector(selectStrategyGoalsTeams);
  const userRole = useSelector(selectUserRole);
  const isTeamManager = useSelector(isLDTeamManager);

  const selectedValue = useMemo(() => {
    if (userRole !== USER_ROLES.ADMIN && isTeamManager && teams.length === 1) {
      return [teams[0]];
    }
    return [];
  }, [userRole, teams, isTeamManager]);

  useEffect(() => {
    dispatch(fetchStrategyTeams());
  }, [dispatch]);

  const changeHandler = useCallback(
    (option: Option) => {
      if (option.value === GOAL_TYPES.COMPANY) {
        onChange(GOAL_TYPES.COMPANY, 'type');
      } else {
        onChange(GOAL_TYPES.TEAM, 'type');
        onChange([option.value], 'goalTeams');
      }
    },
    [onChange]
  );

  useEffect(() => {
    if (teams.length) {
      changeHandler(teams[0]);
    }
  }, [teams, changeHandler]);

  return (
    <FormItem
      label={intl.get('STRATEGY_GOALS.ADD_GOAL_MODAL.ACCOUNTABLE_TEAM')}
      labelProps={{ required: true }}
      className='mb-4'
    >
      <Dropdown
        options={teams}
        values={selectedValue}
        onChange={changeHandler}
        filterable
        triggerProps={{
          placeholder: intl.get(
            'STRATEGY_GOALS.ADD_GOAL_MODAL.ACCOUNTABLE_TEAM_PLACEHOLDER'
          ),
          'data-testid': 'accountable_team_dropdown',
        }}
      />
    </FormItem>
  );
};

export default GoalTeam;

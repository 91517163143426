import { FC, useCallback, useMemo, SyntheticEvent } from 'react';
import intl from 'react-intl-universal';
import { Typography } from '@getsynapse/design-system';
import type { UpdatedDependency } from 'types/store/taskDependencies';
import useModal from 'Hooks/useModal';
import UpdatedDependencies from './UpdatedDependenciesList/UpdatedDependencies';
import CalendarIcon from 'assets/icons/calendar.svg';

interface DependenciesUpdateProps {
  shouldDisplay: boolean;
  toggleDisplay: () => void;
  updatedDependencies?: UpdatedDependency[];
  onConfirm: (shouldShiftDates: boolean) => void;
}

const DependenciesUpdate: FC<DependenciesUpdateProps> = ({
  shouldDisplay,
  toggleDisplay,
  updatedDependencies = [],
  onConfirm,
}) => {
  const { Modal, modalProps, closeModal } = useModal();

  const handleCloseModal = useCallback(() => {
    toggleDisplay();
    closeModal();
  }, [toggleDisplay, closeModal]);

  const validateIfShouldCloseModal = useCallback(
    (event?: SyntheticEvent<Element, Event>) => {
      const target = event?.target as HTMLElement;
      if (!target.hasAttribute('data-reach-dialog-overlay')) {
        handleCloseModal();
      }
    },
    [handleCloseModal]
  );

  const actionButtons = useMemo(
    () => [
      {
        children: intl.get(
          'TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.DEPENDENCIES_UPDATE_MODAL.CONFIRM'
        ),
        variant: 'secondary',
        onClick: () => onConfirm(true),
        className: 'h-10',
      },
      {
        children: intl.get(
          'TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.DEPENDENCIES_UPDATE_MODAL.CANCEL'
        ),
        variant: 'secondary',
        className: 'h-10',
        onClick: () => onConfirm(false),
      },
    ],
    [onConfirm]
  );

  return (
    <Modal
      {...modalProps}
      overlayClassName='z-50'
      isOpen={shouldDisplay}
      title={intl.get(
        'TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.DEPENDENCIES_UPDATE_MODAL.TITLE'
      )}
      aria-label={intl.get(
        'TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.DEPENDENCIES_UPDATE_MODAL.TITLE'
      )}
      titleIcon={{
        src: CalendarIcon,
        className: 'fill-current text-warning-darker',
      }}
      closeModal={validateIfShouldCloseModal}
      actionButtons={actionButtons}
      size='large'
    >
      <div className='flex flex-col gap-y-5'>
        <Typography variant='body'>
          {intl.getHTML(
            'TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.DEPENDENCIES_UPDATE_MODAL.MESSAGE',
            { num: updatedDependencies.length }
          )}
        </Typography>
        <div className='flex flex-col gap-y-3 bg-neutral-white'>
          <Typography variant='body'>
            {intl.getHTML(
              'TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.DEPENDENCIES_UPDATE_MODAL.QUESTION',
              { num: updatedDependencies.length }
            )}
          </Typography>
          <UpdatedDependencies updatedDependencies={updatedDependencies} />
        </div>
      </div>
    </Modal>
  );
};

export default DependenciesUpdate;

import { useEffect, FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import intl from 'react-intl-universal';
import { Alert } from '@reach/alert';
import { Snackbar } from '@getsynapse/design-system';
import {
  selectAddDependenciesStatus,
  selectRemoveDependenciesStatus,
  selectConflictingDependencies,
  selectFailedDependenciesWithoutConflicts,
  setAddDependenciesStatus,
  setRemoveDependenciesStatus,
  setFailedDependencies,
  setCreatedDependencies,
  selectCreatedDependencies,
  selectCreatedDependenciesWithOverlappingDates,
} from 'state/TaskDependencies/TaskDependenciesSlice';
import { SLICE_STATUS } from 'utils/constants';
import {
  FailedDependency,
  LinkedTaskWithRelationshipType,
} from 'types/store/taskDependencies';
import useSnackbarNotifications from '../../hooks/useSnackbarNotifications';

const ActionsNotifications: FC<{ currentTask: string }> = ({ currentTask }) => {
  const dispatch = useDispatch();
  const failedDependenciesWithoutConflicts = useSelector(
    selectFailedDependenciesWithoutConflicts
  );
  const conflictingDependencies = useSelector(selectConflictingDependencies);
  const createdDependencies = useSelector(selectCreatedDependencies);
  const createdDependenciesWithOverlappingDates = useSelector(
    selectCreatedDependenciesWithOverlappingDates
  );
  const addDependenciesStatus = useSelector(selectAddDependenciesStatus);
  const removeDependenciesStatus = useSelector(selectRemoveDependenciesStatus);

  const { notifications, showNotification } = useSnackbarNotifications();

  const generatedFailedDependenciesList = useCallback(
    (
      failedDependencies: FailedDependency[] | LinkedTaskWithRelationshipType[]
    ) => {
      return (
        <ul className='list-disc pl-4'>
          {failedDependencies.map((dependency) => (
            <li
              key={dependency.id}
              className='font-semibold'
              aria-label={dependency.displayId}
            >
              {dependency.displayId}
            </li>
          ))}
        </ul>
      );
    },
    []
  );

  useEffect(() => {
    if (addDependenciesStatus === SLICE_STATUS.SUCCESS) {
      showNotification({
        variant: 'success',
        title: '',
        message: intl.get(
          'TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.DEPENDENCIES_ADDED_SUCCESS',
          { num: createdDependencies?.length }
        ),
        onCloseCallback: () => {
          dispatch(setAddDependenciesStatus(undefined));
          dispatch(setCreatedDependencies(undefined));
        },
      });
    }
  }, [dispatch, addDependenciesStatus, showNotification, createdDependencies]);

  useEffect(() => {
    if (
      createdDependenciesWithOverlappingDates &&
      createdDependenciesWithOverlappingDates.length > 0
    ) {
      showNotification({
        variant: 'warning',
        title: intl.get(
          'TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.NEW_DEPENDENCIES_WITH_OVERLAPPING_DATES.TITLE'
        ),
        message: intl.getHTML('FAILED_DEPENDENCIES').d(
          <span>
            {intl.getHTML(
              'TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.NEW_DEPENDENCIES_WITH_OVERLAPPING_DATES.MESSAGE',
              {
                currentTask,
                num: createdDependenciesWithOverlappingDates.length,
              }
            )}
            {generatedFailedDependenciesList(
              createdDependenciesWithOverlappingDates
            )}
          </span>
        ),
        onCloseCallback: () => {
          dispatch(setAddDependenciesStatus(undefined));
          dispatch(setCreatedDependencies(undefined));
        },
      });
    }
  }, [
    dispatch,
    currentTask,
    createdDependenciesWithOverlappingDates,
    showNotification,
    createdDependencies,
    generatedFailedDependenciesList,
  ]);

  useEffect(() => {
    if (failedDependenciesWithoutConflicts.length > 0) {
      showNotification({
        variant: 'error',
        title: intl.get(
          'TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.FAILED_DEPENDENCIES.TITLE'
        ),
        message: intl.getHTML('FAILED_DEPENDENCIES').d(
          <span>
            {intl.getHTML(
              'TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.FAILED_DEPENDENCIES.MESSAGE',
              { currentTask }
            )}
            {generatedFailedDependenciesList(
              failedDependenciesWithoutConflicts
            )}
          </span>
        ),
        onCloseCallback: () => {
          dispatch(setFailedDependencies(undefined));
          dispatch(setAddDependenciesStatus(undefined));
        },
      });
    }
  }, [
    dispatch,
    showNotification,
    currentTask,
    failedDependenciesWithoutConflicts,
    generatedFailedDependenciesList,
  ]);

  useEffect(() => {
    if (conflictingDependencies.length === 1) {
      showNotification({
        variant: 'error',
        title: intl.get(
          'TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.CONFLICTING_DEPENDENCY.TITLE'
        ),
        message: intl.getHTML(
          'TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.CONFLICTING_DEPENDENCY.MESSAGE',
          {
            currentTask,
            failedDependency: conflictingDependencies[0].displayId,
          }
        ),
        onCloseCallback: () => {
          dispatch(setFailedDependencies(undefined));
          dispatch(setAddDependenciesStatus(undefined));
        },
      });
    }
    if (conflictingDependencies.length > 1) {
      showNotification({
        variant: 'error',
        title: intl.get(
          'TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.CONFLICTING_DEPENDENCIES.TITLE'
        ),
        message: intl.getHTML('FAILED_DEPENDENCIES').d(
          <span>
            {intl.getHTML(
              'TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.CONFLICTING_DEPENDENCIES.MESSAGE',
              { currentTask }
            )}
            {generatedFailedDependenciesList(conflictingDependencies)}
          </span>
        ),
        onCloseCallback: () => {
          dispatch(setFailedDependencies(undefined));
          dispatch(setAddDependenciesStatus(undefined));
        },
      });
    }
  }, [
    dispatch,
    showNotification,
    conflictingDependencies,
    currentTask,
    generatedFailedDependenciesList,
  ]);

  useEffect(() => {
    if (removeDependenciesStatus === SLICE_STATUS.SUCCESS) {
      showNotification({
        variant: 'success',
        title: '',
        message: intl.get(
          'TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.REMOVE_DEPENDENCY_SUCCESS'
        ),
        onCloseCallback: () => {
          dispatch(setRemoveDependenciesStatus(undefined));
        },
      });
    }
  }, [dispatch, showNotification, removeDependenciesStatus]);

  useEffect(() => {
    if (removeDependenciesStatus === SLICE_STATUS.FAILED) {
      showNotification({
        variant: 'error',
        title: intl.get(
          'TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.REMOVE_DEPENDENCY_ERROR.TITLE'
        ),
        message: intl.getHTML(
          'TASKS.TASK_DETAIL_PAGE.DEPENDENCIES.REMOVE_DEPENDENCY_ERROR.MESSAGE'
        ),
        onCloseCallback: () => {
          dispatch(setRemoveDependenciesStatus(undefined));
        },
      });
    }
  }, [dispatch, showNotification, removeDependenciesStatus]);

  if (notifications.length === 0) {
    return null;
  }

  return (
    <div className='fixed left-6 bottom-12 z-50 flex flex-col gap-y-2'>
      {notifications.map((notification) => (
        <Alert key={notification.id} type='assertive'>
          <Snackbar {...notification} />
        </Alert>
      ))}
    </div>
  );
};

export default ActionsNotifications;

import { TaskStatus } from './tasks';

export interface LinkedTask {
  id: string;
  displayId: string;
  name: string;
  startDate: string;
  dueDate: string;
  status: TaskStatus;
  disabled?: boolean;
}

export interface LinkedTaskPermissions {
  canDelete: boolean;
  canEdit: boolean;
  canView: boolean;
}

export enum RelationshipType {
  DependsOn = 'predecessors',
  Blocks = 'successors',
}

export enum FailedDependencyError {
  CircularDependency = 'Circular dependency found',
  Database = 'Database error',
  Unknown = 'Unknown error',
}

export interface FailedDependency {
  id: string;
  displayId?: string;
  error: string;
}

export interface LinkedTaskWithRelationshipType extends LinkedTask {
  relationshipType: RelationshipType;
}

export interface LinkedTaskToRemove {
  id: string;
  displayId: string;
  name: string;
  relationshipType: RelationshipType;
}

export interface UpdatedDependency {
  id: string;
  displayId: string;
  name: string;
  originalStartDate: string;
  originalDueDate: string;
  newStartDate: string;
  newDueDate: string;
  disabled?: boolean;
}

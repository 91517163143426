import { FC } from 'react';
import { Tooltip, Typography } from '@getsynapse/design-system';

interface TruncatedDependencyTitleProps {
  displayId: string;
  name: string;
}

const DependencyTitle: FC<{ title: string; className?: string }> = ({
  title,
  className = 'text-primary',
}) => (
  <Typography variant='label' className={className}>
    {title}
  </Typography>
);

const TruncatedDependencyTitle: FC<TruncatedDependencyTitleProps> = ({
  name,
  displayId,
}) => {
  const shouldTruncateName = name.length > 36;

  if (shouldTruncateName) {
    const truncatedName = `${name.slice(0, 36)}...`;
    return (
      <Tooltip
        openMode='hover2'
        timeout={0}
        usePortal
        showPopper
        trigger={
          <div>
            <DependencyTitle title={`${displayId} - ${truncatedName}`} />
          </div>
        }
        contentProps={{
          className: 'bg-neutral-darker z-50 px-2 py-1',
        }}
      >
        <DependencyTitle
          title={`${displayId} - ${name}`}
          className='text-neutral-white'
        />
      </Tooltip>
    );
  }

  return (
    <DependencyTitle
      title={`${displayId} - ${name}`}
      className='text-primary'
    />
  );
};

export default TruncatedDependencyTitle;

import { FC } from 'react';
import { TableRow, tailwindOverride } from '@getsynapse/design-system';
import { getRequestRoute } from 'utils/dynamicRoutes';

import { Request } from 'types/store/normalized';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectIsUserLd, selectOrganizationId } from 'state/User/userSlice';
import RequestStatusTag from 'Atoms/RequestStatusTag/RequestStatusTag';
import { formatDate, formatRequestLearningPriority } from 'utils/formatters';

interface TableRowProps {
  request: Request;
  isOdd?: boolean;
}

const RequestTableRow: FC<TableRowProps> = ({ request, isOdd }) => {
  const history = useHistory();
  const organizationId = useSelector(selectOrganizationId)!;
  const isLearningUser = useSelector(selectIsUserLd);

  return (
    <TableRow
      className={tailwindOverride(
        'cursor-pointer',
        isOdd ? 'bg-neutral-lightest-two' : 'bg-neutral-white'
      )}
      key={request.id}
      onClick={() => history.push(getRequestRoute(request.id, organizationId))}
      cells={[
        {
          content: request.number,
        },
        {
          content: (
            <div className='w-64 truncate'>
              <Link
                to={getRequestRoute(request.id, organizationId)}
                title={request.title}
              >
                {request.title}
              </Link>
            </div>
          ),
        },
        {
          content: <RequestStatusTag status={request.status} />,
        },
        ...(isLearningUser
          ? [
              {
                content: formatRequestLearningPriority(
                  request.learnAndDevelopmentPriority
                ),
              },
            ]
          : []),
        {
          content: formatDate(request.createdAt),
        },
        {
          content: formatDate(request.submittedAt),
        },
        {
          content: formatDate(request.decisionDate),
        },
        {
          content: formatDate(request.updatedAt),
        },
      ]}
    />
  );
};

export default RequestTableRow;

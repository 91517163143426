import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  getRequests,
  requestsSelectors,
  setSearchParameter,
} from 'state/normalized/requests/requestsSlice';
import { useAppThunkDispatch } from 'state/store';
import { SLICE_STATUS } from 'utils/constants';

import { Request } from 'types/store/normalized';
import { RequestStatus } from 'utils/types/request';

interface UseRequestsTableParameters {
  statuses?: readonly RequestStatus[];
}

interface UseRequestsTableResponse {
  requests: Request[];
  loading: boolean;
  canFetchMore: boolean;
  onFetchMore: () => void;
  searchRequests: (search: string) => void;
}
const useRequestsTable = ({
  statuses,
}: UseRequestsTableParameters): UseRequestsTableResponse => {
  const dispatch = useAppThunkDispatch();
  const requests = useSelector(requestsSelectors.selectAll);
  const status = useSelector(requestsSelectors.selectStatus);
  const canFetchMore = useSelector(requestsSelectors.selectCanFetchMore);
  const searchParameter = useSelector(requestsSelectors.selectSearchParameter);

  const loading: boolean =
    status === SLICE_STATUS.LOADING && requests.length === 0;

  useEffect(() => {
    dispatch(getRequests({ status: statuses }));

    return () => {
      dispatch(setSearchParameter(''));
    };
  }, [dispatch, statuses]);

  const searchRequests = (search: string) => {
    if (search === searchParameter) return;
    dispatch(setSearchParameter(search));
    dispatch(getRequests({ status: statuses }));
  };

  const onFetchMore = () => {
    dispatch(
      getRequests({
        status: statuses,
        fetchNext: true,
      })
    );
  };

  return {
    requests,
    loading,
    canFetchMore,
    onFetchMore,
    searchRequests,
  };
};

export default useRequestsTable;

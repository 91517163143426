import { api } from './api';
import axios from 'axios';
import { TaskAssignee } from './taskTypes';

export interface ListAvailableTaskaAssigneeResponse {
  availableAssignees: TaskAssignee[];
}

export interface FetchAvailableTaskAssigneesParams {
  taskId?: string;
  assigneeName?: string;
  userIds?: string[];
  limit?: number;
  includeDisabled?: boolean;
}

export const fetchAvailableTaskAssignees = async ({
  taskId,
  userIds,
  assigneeName,
  limit,
  includeDisabled,
}: FetchAvailableTaskAssigneesParams) => {
  let url;
  if (taskId) {
    url = `/v2/tasks/${taskId}/available-assignees`;
  } else {
    url = `/v2/available-assignees`;
  }

  if (assigneeName) {
    url = `${url}?assigneeName=${encodeURIComponent(assigneeName)}`;
  }

  if (limit) {
    if (url.includes('?')) {
      url = `${url}&limit=${limit}`;
    } else {
      url = `${url}?limit=${limit}`;
    }
  }

  if (userIds) {
    let params = userIds
      .map((id) => `userIds[]=${encodeURIComponent(id)}`)
      .join('&');

    if (url.includes('?')) {
      url = `${url}&${params}`;
    } else {
      url = `${url}?${params}`;
    }
  }

  if (includeDisabled) {
    if (url.includes('?')) {
      url = `${url}&includeDisabled=true`;
    } else {
      url = `${url}?includeDisabled=true`;
    }
  }

  const response = await api.get<ListAvailableTaskaAssigneeResponse>(url);
  if (axios.isAxiosError(response)) {
    throw response;
  }
  return response.data;
};

import { FC } from 'react';
import { tailwindOverride } from '@getsynapse/design-system';
import type { UpdatedDependency } from 'types/store/taskDependencies';
import UpdatedDependencyItem from '../UpdatedDependencyItem/UpdatedDependency';

interface UpdatedDependenciesProps {
  updatedDependencies: UpdatedDependency[];
}

const UpdatedDependencies: FC<UpdatedDependenciesProps> = ({
  updatedDependencies,
}) => {
  return (
    <div
      className={tailwindOverride(
        'w-full max-h-68 overflow-y-auto bg-neutral-white',
        'rounded border border-neutral-lighter-two'
      )}
    >
      {updatedDependencies.map((dependency) => (
        <UpdatedDependencyItem key={dependency.id} {...dependency} />
      ))}
    </div>
  );
};

export default UpdatedDependencies;

import { ProjectTasksSliceState } from 'types/store/projectTasksList';
import { FetchTasksListApiParams } from 'api/projectTasks';
import { getAfterQueryParamFromNextLink } from 'state/TasksList/helpers';

export interface FetchProjectTasksListParams {
  projectId: string;
  userId?: string;
  fetchNext?: boolean;
  includeTotalCount?: boolean;
}

export const getFetchProjectsTasksListQueryParams: (
  params: FetchProjectTasksListParams,
  state: ProjectTasksSliceState
) => FetchTasksListApiParams = (params, state) => {
  let queryParams: FetchTasksListApiParams = {};
  queryParams['projectId'] = params.projectId;
  queryParams['disabled'] = true;
  queryParams['sortBy'] = 'projectOrder';
  queryParams['order'] = 'asc';

  if (params.userId) {
    queryParams['user'] = [params.userId];
  }

  if (params.fetchNext) {
    const next = state.links.next;
    queryParams['after'] = getAfterQueryParamFromNextLink(next);
  }

  if (params.includeTotalCount) {
    queryParams['includeTotalCount'] = true;
  }

  if (state.sorting) {
    queryParams['sortBy'] = state.sorting.sortBy;
    queryParams['order'] = state.sorting.order;
  }

  if (state.filters && Object.keys(state.filters).length > 0) {
    queryParams = { ...queryParams, ...state.filters };
  }

  return queryParams;
};

import { Table } from '@getsynapse/design-system';
import { Request } from 'types/store/normalized';
import SkeletonLoader from 'Organisms/TableSkeletonLoader/PageTableLoader';
import NoRequestsTable from 'Molecules/NoRequestsTable/NoRequestsTable';
import RequestsTableBody from './RequestsTableBody';
import RequestsTableHeader from './RequestsTableHeader';

interface RequestsTableProps {
  requests: Request[];
  loading?: boolean;
  canFetchMore?: boolean;
  onFetchMore?: () => void;
}

const RequestsTable = ({
  requests,
  loading = false,
  canFetchMore = false,
  onFetchMore = () => {},
}: RequestsTableProps) => {
  if (loading) {
    return <SkeletonLoader />;
  }

  const tableData = {
    headData: {
      headCells: [],
    },
    rows: [],
  };

  return (
    <Table
      className='w-full'
      canSelectRows={false}
      data={tableData}
      emptyComponent={<NoRequestsTable />}
    >
      <RequestsTableHeader />
      <RequestsTableBody
        requests={requests}
        canFetchMore={canFetchMore}
        fetchMore={onFetchMore}
      />
    </Table>
  );
};

export default RequestsTable;

import { FC, FormEvent } from 'react';
import intl from 'react-intl-universal';
import { tailwindOverride, Button } from '@getsynapse/design-system';
import { NewActualHoursEntry } from 'types/store/actualHours';
import useAddActualHoursEntries from '../hooks/useAddActualHoursEntries';
import HoursFormItem from './HoursFormItem';

interface AddHoursFormProps {
  userId: string;
  updateNewActualHoursEntries: (
    newActualHoursEntries: NewActualHoursEntry[]
  ) => void;
}

const AddHoursForm: FC<AddHoursFormProps> = ({
  userId,
  updateNewActualHoursEntries,
}) => {
  const { actualHoursEntries, updateEntry, addEntry, removeEntry } =
    useAddActualHoursEntries({
      userId,
      updateNewActualHoursEntries,
    });

  const displayDeleteButton = Object.keys(actualHoursEntries).length > 1;

  return (
    <form
      aria-label='add-hours-form'
      onSubmit={(e: FormEvent<HTMLFormElement>) => e.preventDefault()}
      className={tailwindOverride('w-full flex flex-col gap-y-4')}
    >
      {Object.keys(actualHoursEntries).map((entryId) => (
        <HoursFormItem
          key={entryId}
          id={entryId}
          updateEntry={updateEntry}
          displayDeleteButton={displayDeleteButton}
          removeEntry={removeEntry}
        />
      ))}
      <Button
        onClick={addEntry}
        variant='tertiary'
        iconName='add-circle'
        className='self-start'
      >
        {intl.get('TASK_ACTUAL_HOURS.ADD_ENTRY')}
      </Button>
    </form>
  );
};

export default AddHoursForm;

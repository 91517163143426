import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Status } from 'utils/customTypes';
import { SLICE_STATUS } from 'utils/constants';
import { StrategyGoal } from 'utils/types/strategyGoals';
import { RootState } from 'state/store';
import projectGoalsAPI from './ProjectGoalsAPI';

interface ProjectGoalsState {
  status: Status;
  value: {
    goals: Omit<StrategyGoal, 'owners'>[];
    totalGoals: number;
  };
}

/* ============================= INITIAL STATE ============================== */

const initialState: ProjectGoalsState = {
  value: {
    goals: [],
    totalGoals: 0,
  },
  status: SLICE_STATUS.IDLE,
};

/* ============================= ACTIONS ============================== */

/* ============================== REDUX THUNK =============================== */
export const fetchProjectGoals = createAsyncThunk(
  'projectGoals/FETCH_PROJECT_GOALS',
  async (projectId: string) => {
    const response = await projectGoalsAPI.fetchProjectGoals(projectId);
    return response?.data;
  }
);
/* ================================= REDUCER ================================ */
const projectGoalsSlice = createSlice({
  name: 'projectGoals',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectGoals.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchProjectGoals.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchProjectGoals.fulfilled, (state, action) => {
        state.value = action.payload;
        state.status = SLICE_STATUS.IDLE;
      });
  },
});

/* =============================== SELECTORS ================================ */

export const selectProjectGoals = (state: RootState) =>
  state.projectGoals.value.goals;

export const selectTotalProjectGoals = (state: RootState) =>
  state.projectGoals.value.totalGoals;

export const selectProjectGoalsStatus = (state: RootState) =>
  state.projectGoals.status;

export default projectGoalsSlice.reducer;

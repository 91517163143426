import { api } from './api';
import axios from 'axios';
import { TaskAssignee, TaskProject } from './taskTypes';
import { TaskDetailToUpdate } from 'types/store/taskDetail';

export interface Task {
  id: string;
  name: string;
  displayId: string;
  organizationId: string;
  project: TaskProject;
  description: string;
  disabled: boolean;
  status: string;
  type: string;
  startDate: string;
  endDate: string;
  completedDate?: string;
  estimateHours: string;
  actualHours: string;
  assignees: TaskAssignee[];
}

export interface TaskDetailResponse {
  task: Task;
  permissions?: {
    canEdit?: boolean;
    canDelete?: boolean;
  };
}

export interface FetchTaskDetailParams {
  taskId: string;
}

export interface UpdateTaskDetailParams {
  task: TaskDetailToUpdate;
  shift?: 'successors' | 'predecessors' | 'all';
}

export interface UpdateTaskResponse {
  successes: Task[];
  failures: Map<string, Error>;
}

export const fetchTask = async ({ taskId }: FetchTaskDetailParams) => {
  const url = `/v2/tasks/${taskId}`;
  const response = await api.get<TaskDetailResponse>(url);
  if (axios.isAxiosError(response)) {
    throw response;
  }
  return response.data;
};

export const updateTask = async ({ task, shift }: UpdateTaskDetailParams) => {
  const url = `/v2/tasks`;
  let config = {};
  if (shift) {
    config = {
      headers: {
        shift,
      },
    };
  }
  const response = await api.patch<UpdateTaskResponse>(
    url,
    { tasks: [task] },
    config
  );
  if (axios.isAxiosError(response)) {
    throw response;
  }

  if (response.data.failures.size > 0) {
    throw new Error('Failed to update task');
  }

  return response.data.successes[0];
};

export const deleteTask = async (taskId: string) => {
  const url = `/v2/tasks/${taskId}`;
  const response = await api.delete(url);
  if (axios.isAxiosError(response)) {
    throw response;
  }
  return response.data;
};

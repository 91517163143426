import { FC } from 'react';
import { Droppable, DroppableProvided } from 'react-beautiful-dnd';
import { TaskWithAssignees } from 'types/store/projectTasksList';
import { ProjectTasksTableTab } from 'utils/customTypes';
import TaskRow from './TaskRow';
import LastTaskRow from './LastTaskRow';
import useTaskRowActions from './useTaskRowActions';
import EmptyTableBody from './EmptyTableBody';

interface TableBodyProps {
  tableName: ProjectTasksTableTab;
  tasks: TaskWithAssignees[];
  canFetchMoreTasks: boolean;
  fetchMoreTasks: () => void;
  showDisabledColumn?: boolean;
  canReorderTasks?: boolean;
  isDragging?: boolean;
}

const TableBody: FC<TableBodyProps> = ({
  tasks,
  tableName,
  canFetchMoreTasks,
  fetchMoreTasks,
  showDisabledColumn = false,
  canReorderTasks = false,
  isDragging = false,
}) => {
  const isListEmpty = tasks.length === 0;
  const {
    openTakInSidePanel,
    handleClickTaskRow,
    handleDuplicateTask,
    handleSelectRow,
    isTaskRowSelected,
  } = useTaskRowActions();

  return (
    <Droppable droppableId={tableName}>
      {(droppableProvided: DroppableProvided) => (
        <tbody
          ref={droppableProvided.innerRef}
          {...droppableProvided.droppableProps}
        >
          {tasks.map((task: TaskWithAssignees, index: number) => {
            const isOdd = index % 2 !== 0;
            return (
              <TaskRow
                key={task.id}
                index={index}
                task={task}
                bgColor={isOdd ? 'bg-neutral-lightest-two' : 'bg-neutral-white'}
                showDisabledColumn={showDisabledColumn}
                openTakInSidePanel={openTakInSidePanel}
                handleClickTaskRow={handleClickTaskRow}
                handleDuplicateTask={handleDuplicateTask}
                canReorderTasks={canReorderTasks}
                isTaskRowSelected={isTaskRowSelected(task.id)}
                handleSelectRow={handleSelectRow}
              />
            );
          })}
          {!isListEmpty && !isDragging && canFetchMoreTasks && (
            <LastTaskRow
              isOdd={tasks.length % 2 !== 0}
              tableName={tableName}
              onLastRowInView={fetchMoreTasks}
              showDisabledColumn={showDisabledColumn}
            />
          )}
          {isListEmpty && (
            <tr aria-label={`${tableName}-empty-body`}>
              <td colSpan={10}>
                <EmptyTableBody />
              </td>
            </tr>
          )}
          {droppableProvided.placeholder}
        </tbody>
      )}
    </Droppable>
  );
};

export default TableBody;

import { useSelector } from 'react-redux';

import RequestsTable from 'Organisms/RequestsTable/RequestsTable';
import RequestsTableFooter from 'Organisms/RequestsTable/RequestsTableFooter';
import RequestsTableOperationHeader from 'Organisms/RequestsTable/RequestsTableOperationHeader';

import { ACTIVE_REQUEST_STATUSES } from 'utils/constants/request';
import { requestsSelectors } from 'state/normalized/requests/requestsSlice';
import useRequestsTable from 'Hooks/Requests/useRequestsTable';

const ActiveRequestsTable = () => {
  const { requests, loading, canFetchMore, onFetchMore, searchRequests } =
    useRequestsTable({
      statuses: ACTIVE_REQUEST_STATUSES,
    });
  const totalCount = useSelector(requestsSelectors.selectTotalRequests);
  const loadedCount = useSelector(requestsSelectors.selectLoadedRequests);

  return (
    <div className='w-full rounded-b max-h-small-table overflow-auto border border-neutral-lighter-two'>
      <RequestsTableOperationHeader onSearchChange={searchRequests} />
      <RequestsTable
        requests={requests}
        loading={loading}
        canFetchMore={canFetchMore}
        onFetchMore={onFetchMore}
      />
      <RequestsTableFooter total={totalCount} loaded={loadedCount} />
    </div>
  );
};

export default ActiveRequestsTable;

import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from 'state/store';
import {
  selectAssigneesListByIds,
  setTasksAssignees,
} from 'state/TasksList/allTasks/allTasksSlice';
import { fetchTasksList, exportTasksList } from 'api/projectTasks';
import { ProjectTasksSliceState } from 'types/store/projectTasksList';
import { getFetchProjectsTasksListQueryParams } from '../helpers';
import { SLICE_STATUS } from 'utils/constants';
import { DEFAULT_TASK_ELEMENT_PERMISSIONS } from 'utils/constants/centralizedTasks';

const initialState: ProjectTasksSliceState = {
  tasks: [],
  links: {
    next: null,
  },
};

interface FetchTasksListParams {
  projectId: string;
  userId: string;
  fetchNext?: boolean;
  includeTotalCount?: boolean;
}

export const fetchUserTasks = createAsyncThunk(
  'projectTasksList/userTasks/fetchMyTasks',
  async (
    params: FetchTasksListParams,
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      const state = getState() as RootState;
      const userTasksState = state.projectTasksList.userTasks;
      const queryParams = getFetchProjectsTasksListQueryParams(
        params,
        userTasksState
      );
      const response = await fetchTasksList(queryParams);
      dispatch(setTasksAssignees(response.assignees));
      return {
        tasks: response.tasks,
        links: response.links,
        fetchNext: params.fetchNext,
        totalCount: response.totalCount,
        permissions: response.permissions,
      };
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue('Error fetching user tasks');
    }
  }
);

export const exportUserTasks = createAsyncThunk(
  'projectTasksList/userTasks/exportUserTasks',
  async (params: FetchTasksListParams, { rejectWithValue, getState }) => {
    try {
      const userTasksState = (getState() as RootState).projectTasksList
        .userTasks;
      const filters = userTasksState.filters
        ? { ...userTasksState.filters }
        : {};
      const actionsState = (getState() as RootState).projectTasksList.actions;
      if (!('disabled' in filters)) {
        filters.disabled = true;
      }
      const response = await exportTasksList({
        ...filters,
        projectId: params.projectId,
        user: [params.userId],
        exportAll: actionsState.selectAllTasks,
        excludedIds: actionsState.excludedTasks,
        selectedIds: actionsState.selectedTasks,
      });
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue(error);
    }
  }
);

const userTasksSlice = createSlice({
  name: 'projectTasksList/userTasks',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setSearchFilter: (state, action) => {
      state.filters = {
        ...state.filters,
        search: action.payload,
      };
    },
    resetFilters: (state) => {
      state.filters = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserTasks.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchUserTasks.fulfilled, (state, action) => {
        let updatedTasks = action.payload.tasks;
        let permissions = action.payload.permissions;
        if (action.payload.fetchNext) {
          updatedTasks = state.tasks.concat(action.payload.tasks);
          permissions = {
            ...state.permissions,
            ...action.payload.permissions,
          };
        }
        state.tasks = updatedTasks;
        state.permissions = permissions;
        state.links = action.payload.links;
        if (action.payload.totalCount !== undefined) {
          state.totalCount = action.payload.totalCount;
        }
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(fetchUserTasks.rejected, (state, action) => {
        state.status = SLICE_STATUS.FAILED;
        throw new Error(action.payload as string);
      });
  },
});

const selectTasks = (state: RootState) =>
  state.projectTasksList.userTasks.tasks;
const selectTasksPermissions = (state: RootState) =>
  state.projectTasksList.userTasks.permissions;
export const selectUserTasksLoading = (state: RootState) =>
  state.projectTasksList.userTasks.status;
export const selectUserTasksFilters = (state: RootState) =>
  state.projectTasksList.userTasks.filters ?? {};

export const selectUserTasks = createSelector(
  [(state: RootState) => state, selectTasks, selectTasksPermissions],
  (
    state: RootState,
    tasks: ProjectTasksSliceState['tasks'],
    permissions: ProjectTasksSliceState['permissions']
  ) => {
    return tasks.map((task) => {
      return {
        ...task,
        assignees: selectAssigneesListByIds(state, task.assignees),
        permissions: permissions
          ? permissions[task.id]
          : DEFAULT_TASK_ELEMENT_PERMISSIONS,
      };
    });
  }
);

export const selectTotalCount = (state: RootState) =>
  state.projectTasksList.userTasks.totalCount;

export const selectCanFetchtMoreTasks = (state: RootState) =>
  state.projectTasksList.userTasks.links.next !== null;

export const { setFilters, setSearchFilter, resetFilters } =
  userTasksSlice.actions;

export default userTasksSlice.reducer;

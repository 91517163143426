import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';
import intl from 'react-intl-universal';
import { Status } from 'utils/customTypes';
import { SLICE_STATUS, USER_ROLES, USER_STATUS } from 'utils/constants';
import {
  GoalOwner,
  StrategyGoal,
  GoalTeam,
  NewGoalData,
} from 'utils/types/strategyGoals';
import { RootState } from 'state/store';
import strategyGoalsAPI from './StrategyGoalsAPI';
import { GOAL_TYPES } from 'utils/constants/strategyGoals';

interface StrategyGoalsState {
  status: Status;
  value: StrategyGoal[];
  owners: GoalOwner[];
  teams: GoalTeam[];
}

/* ============================= INITIAL STATE ============================== */

const initialState: StrategyGoalsState = {
  value: [] as StrategyGoal[],
  status: SLICE_STATUS.IDLE,
  owners: [],
  teams: [],
};

/* ============================= ACTIONS ============================== */

/* ============================== REDUX THUNK =============================== */
export const fetchStrategyGoals = createAsyncThunk(
  'strategyGoals/FETCH_STRATEGY_GOALS',
  async () => {
    const response = await strategyGoalsAPI.fetchStrategyGoals();
    return response?.data;
  }
);

export const fetchStrategyOwners = createAsyncThunk(
  'strategyGoals/FETCH_STRATEGY_OWNERS',
  async () => {
    const response = await strategyGoalsAPI.fetchOwnersList();
    return response?.data;
  }
);

export const fetchStrategyTeams = createAsyncThunk(
  'strategyGoals/FETCH_STRATEGY_TEAMS',
  async () => {
    const response = await strategyGoalsAPI.fetchTeamsList();
    return response?.data;
  }
);

export const createGoal = createAsyncThunk(
  'strategyGoals/CREATE_GOAL',
  async (newGoalData: NewGoalData) => {
    const response = await strategyGoalsAPI.createStrategyGoal(newGoalData);
    return response?.data;
  }
);
/* ================================= REDUCER ================================ */
const strategyGoalsSlice = createSlice({
  name: 'strategyGoals',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStrategyGoals.pending, (state) => {
        state.status = SLICE_STATUS.LOADING;
      })
      .addCase(fetchStrategyGoals.rejected, (state) => {
        state.status = SLICE_STATUS.FAILED;
      })
      .addCase(fetchStrategyGoals.fulfilled, (state, action) => {
        state.value = action.payload;
        state.status = SLICE_STATUS.IDLE;
      })
      .addCase(fetchStrategyOwners.fulfilled, (state, action) => {
        state.owners = action.payload;
      })
      .addCase(fetchStrategyTeams.fulfilled, (state, action) => {
        state.teams = action.payload;
      })
      .addCase(createGoal.rejected, () => {
        throw new Error();
      })
      .addCase(createGoal.fulfilled, (state, action) => {
        state.value.push(action.payload);
      });
  },
});

/* =============================== SELECTORS ================================ */

export const selectStrategyGoals = (state: RootState) =>
  state.strategyGoals.value;

export const selectStrategyGoalsStatus = (state: RootState) =>
  state.strategyGoals.status;

export const selectStrategyGoalsOwners = (state: RootState) =>
  state.strategyGoals.owners.map((owner) => ({
    label: owner.name,
    value: owner.id,
    avatar: {
      imageSrc: owner.avatarUrl!,
      initial: owner.initials,
      name: owner.name,
    },
    disabled:
      owner.status === USER_STATUS.REGISTERED_DISABLED ||
      owner.status === USER_STATUS.INVITED_DISABLED,
  }));

export const selectActiveGoalsOwners = createSelector(
  [selectStrategyGoalsOwners],
  (owners) => owners.filter((owner) => !owner.disabled)
);

export const selectStrategyGoalsTeams = (state: RootState) => {
  const { teams } = state.strategyGoals;
  const { role } = state.currentUser.value;

  const formattedTeams = teams.map((team) => ({
    label: team.name,
    value: team.id,
  }));

  if (role === USER_ROLES.ADMIN) {
    formattedTeams.unshift({
      label: intl.get('STRATEGY_GOALS.COMPANY_GOAL'),
      value: GOAL_TYPES.COMPANY,
    });
  }

  return formattedTeams;
};

export default strategyGoalsSlice.reducer;

import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';

class ProjectGoalsAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(`${config.get('backendURL')}v2/projects`);
  }

  fetchProjectGoals = async (projectId: string) => {
    const res = await this.instance.get(`/${projectId}/goals`);
    return res;
  };
}

export default new ProjectGoalsAPI();

import { AxiosInstance } from 'axios';
import config from 'config/Config';
import { initAxios } from 'utils/axios';
import { NewGoalData } from 'utils/types/strategyGoals';

class StrategyGoalsAPI {
  instance: AxiosInstance;
  constructor() {
    this.instance = initAxios(`${config.get('backendURL')}v2/strategy/goals`);
  }

  fetchStrategyGoals = async () => {
    const res = await this.instance.get('/');
    return res;
  };

  fetchOwnersList = async () => {
    const res = await this.instance.get('/possible-owners');
    return res;
  };

  fetchTeamsList = async () => {
    const res = await this.instance.get('/possible-ld-teams');
    return res;
  };

  createStrategyGoal = async (newGoalData: NewGoalData) => {
    const res = await this.instance.post('/', newGoalData);
    return res;
  };
}

export default new StrategyGoalsAPI();

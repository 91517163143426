import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectShouldDisplayPreview,
  setShouldDisplayPreview,
  selectUpdatedDependenciesPreview,
} from 'state/TaskDependencies/DependenciesUpdateSlice';
import {
  selectTaskDetailToUpdate,
  updateTaskDetail,
  setShouldRedirectAfterUpdate,
} from 'state/TaskDetail/taskSlice';
import DependenciesUpdate from 'Organisms/TaskDependencies/DependenciesUpdate/DependenciesUpdate';

const DependenciesUpdatePreview = () => {
  const dispatch = useDispatch();
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const shouldDisplayPreview = useSelector(selectShouldDisplayPreview);
  const updatedDependencies = useSelector(selectUpdatedDependenciesPreview);
  const taskDetailToUpdate = useSelector(selectTaskDetailToUpdate);

  const toggleDisplayPreview = useCallback(() => {
    dispatch(setShouldDisplayPreview(false));
    setDisplayModal(false);
  }, [dispatch]);

  useEffect(() => {
    if (shouldDisplayPreview && !displayModal) {
      setDisplayModal(true);
    }

    if (!shouldDisplayPreview && displayModal) {
      setDisplayModal(false);
    }
  }, [displayModal, shouldDisplayPreview]);

  const handleConfirmation = useCallback(
    async (shouldShiftDates: boolean) => {
      dispatch(setShouldRedirectAfterUpdate(true));
      dispatch(
        updateTaskDetail({
          task: taskDetailToUpdate,
          shift: shouldShiftDates ? 'all' : undefined,
        })
      );
      toggleDisplayPreview();
    },
    [dispatch, taskDetailToUpdate, toggleDisplayPreview]
  );

  return (
    <DependenciesUpdate
      shouldDisplay={displayModal}
      toggleDisplay={toggleDisplayPreview}
      updatedDependencies={updatedDependencies}
      onConfirm={handleConfirmation}
    />
  );
};

export default DependenciesUpdatePreview;
